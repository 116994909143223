import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import polyglot from 'services/localization';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faFileSlash,
  faCheck,
  faExclamationCircle,
  faFile,
  faHourglassHalf,
  faWrench,
} from 'fontawesome/pro-solid-svg-icons';
import styles from './statusLabelModern.styles.pcss';

export default function StatusLabel({
  className,
  status,
  gross,
  dueDate,
  isBadge,
  hideLabel,
}) {
  let key;
  let icon;
  switch (status) {
    case 'active':
      key = status;
      icon = <i className="fak fa-offer" />;
      break;
    case 'offer':
      key = status;
      icon = <i className="fak fa-offer" />;
      break;
    case 'draft':
      key = status;
      icon = faWrench;
      break;
    case 'preview':
      key = status;
      icon = faFile;
      break;
    case 'invoice':
      key = status;
      icon = <i className="fak fa-euro" />;
      break;
    case 'open':
      key = moment.utc().isAfter(moment.utc(dueDate)) ? 'overdue' : 'open';
      icon = moment.utc().isAfter(moment.utc(dueDate)) ? faExclamationCircle : faHourglassHalf;
      break;
    case 'paid':
      key = Math.abs(gross) > 0 ? 'paid' : 'paidOut';
      icon = faCheck;
      break;
    case 'overdue':
      key = status;
      icon = faExclamationCircle;
      break;
    case 'paidCredit':
      key = status;
      icon = faFileSlash;
      break;
    case 'approved':
    case 'sent':
    case 'registered':
    case 'calculated':
      key = status;
      icon = faCheckCircle;
      break;
    case 'withoutCalc':
      key = 'withoutCalc';
      icon = faTimesCircle;
      break;
    case 'paidOut':
      key = status;
      icon = faCheck;
      break;
    default:
  }
  return (
    <div className={cx(styles.label, isBadge && styles.badge, isBadge && styles[key], className)}>
      {icon && icon.$$typeof ? (
        <div className={styles.customIcon}>
          {icon}
        </div>
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
      {!hideLabel && (
        <div className={styles.labelText}>
          {polyglot.t(`invoices.statuses.${key}`)}
        </div>
      )}
    </div>
  );
}

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
  gross: PropTypes.number,
  dueDate: PropTypes.string,
  isBadge: PropTypes.bool,
  hideLabel: PropTypes.bool,
};
StatusLabel.defaultProps = {
  className: '',
  gross: 0,
  dueDate: '',
  isBadge: true,
  hideLabel: false,
};
