import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { isInTrial } from 'shared-library/src/services/subscriptionService';
import {
  faCog,
  faTools,
  faSignOutAlt,
  faQuestionCircle,
  faCalendarDay,
  faSearch,
  faPaperPlane,
  faChevronRight,
} from 'fontawesome/pro-solid-svg-icons';
import { createSupportChatConversation } from 'resources/user/user.actions';
import { getDropdownMenuItems, hasPermission } from 'helpers/permissions';
import polyglot from 'services/localization';
import { showSupportSpace } from 'services/supportTools';
import buttonStyles from 'styles/button.pcss';
import Switch from 'components/common/switch';
import Accordion from 'components/common/accordion';
import Notifications from 'components/notifications';
import { PHONE_NUMBERS } from 'constants.js';
import {
  ADDON_NAMES, ACTIVE_STATUSES,
} from 'shared-library/src/definitions/subscription';
import ButtonModern from 'components/common/buttonModern';
import TextareaModern from 'components/common/textareaModern';
import SmileySad from 'components/common/icon/svg/smileySad';
import SmileyHappy from 'components/common/icon/svg/smileyHappy';
import SmileySadMeh from 'components/common/icon/svg/smileySadMeh';
import Webinar from 'components/common/icon/svg/webinar';
import SmileyMeh from 'components/common/icon/svg/smileyMeh';
import SmileyMehHappy from 'components/common/icon/svg/smileyMehHappy';
import ResponsiveModal from 'components/common/responsiveModal';
import Icon from 'components/common/icon/Icon';
import InteractableDiv from 'components/common/interactableDiv';
import DeviceService from 'services/device.service';
import { hasCashRegisterService } from 'shared-library/src/definitions/garageCountries';
import { SUPPORTED_SUPPORT_LOCALES_ARRAY } from 'shared-library/src/definitions/localization';

import CustomLink from 'components/common/customLink';
import styles from './header.styles.pcss';

export default class Header extends PureComponent {
  static propTypes = {
    currentGarageId: PropTypes.string.isRequired,
    garage: PropTypes.object.isRequired,
    garages: PropTypes.arrayOf(PropTypes.object).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      locale: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    changeGarage: PropTypes.func.isRequired,
    subscriptionData: PropTypes.shape({
      status: PropTypes.string,
      trialEnd: PropTypes.string,
    }),
    isCustomerMode: PropTypes.bool.isRequired,
    toggleCustomerModeAction: PropTypes.func.isRequired,
    fetchCalendarsAction: PropTypes.func.isRequired,
    canAccessCalendar: PropTypes.bool.isRequired,
    updateUser: PropTypes.func.isRequired,
    mobileViewOption: PropTypes.string.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    currentRating: PropTypes.number.isRequired,
    submitRating: PropTypes.func.isRequired,
  };

  static defaultProps = {
    subscriptionData: {
      status: '',
      trialEnd: '',
    },
  };

  static smileys = {
    1: {
      icon: <SmileySad />,
      id: 'sad',
    },
    2: {
      icon: <SmileySadMeh />,
      id: 'sadMeh',
    },
    3: {
      icon: <SmileyMeh />,
      id: 'meh',
    },
    4: {
      icon: <SmileyMehHappy />,
      id: 'mehHappy',
    },
    5: {
      icon: <SmileyHappy />,
      id: 'happy',
    },
  };

  static openHours = [
    { id: 'monday' },
    { id: 'tuesday' },
    { id: 'wednesday' },
    { id: 'thursday' },
    { id: 'friday' },
  ];

  constructor(props) {
    super(props);

    this.helpModalRef = React.createRef();
    this.optionsModalRef = React.createRef();
    this.userModalRef = React.createRef();
    this.ratingModalRef = React.createRef();

    this.state = {
      isOptionsDropdownVisible: false,
      isUserDropdownVisible: false,
      isHelpOpen: false,
      isRatingDropdownVisible: false,
      smiley: props.currentRating,
      smileyOnClose: 0,
      improvements: '',
      feedBackModalOpen: false,
      feedBackSent: false,
      isSubmittingImprovements: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isRatingDropdownVisible } = this.state;

    if (prevState.isRatingDropdownVisible && !isRatingDropdownVisible) {
      const { currentRating, submitRating } = this.props;

      if (currentRating === 0) {
        submitRating({ smiley: null });
      }
    }
  }

  checkDocumentClick = (e) => {
    const {
      isOptionsDropdownVisible,
      isUserDropdownVisible,
      isHelpOpen,
      isRatingDropdownVisible,
    } = this.state;
    const handleClick = (ref, hideFunc) => {
      if (ref?.current && !ref.current.contains(e.target)) {
        document.removeEventListener('click', this.checkDocumentClick, true);
        setTimeout(() => {
          hideFunc();
        }, 1);
      }
    };
    if (isOptionsDropdownVisible) handleClick(this.optionsModalRef, this.hideOptionsDropdown);
    if (isUserDropdownVisible) handleClick(this.userModalRef, this.hideUserDropdown);
    if (isHelpOpen) handleClick(this.helpModalRef, this.hideHelpDropdown);
    if (isRatingDropdownVisible) handleClick(this.ratingModalRef, () => this.hideRatingDropdown(e));
  };

  hideRatingDropdown = async (e) => {
    const { currentRating } = this.props;
    const { smiley, smileyOnClose } = this.state;
    const position = e.clientX - document.getElementById('smiley').getBoundingClientRect().left;
    const positionY = e.clientY - document.getElementById('smiley').getBoundingClientRect().top;
    try {
      if (position > 45 || position < -345 || positionY < 55 || positionY > 484) {
        if (smiley !== 0) {
          if (smileyOnClose === 0) {
            if (currentRating !== smiley) {
              await this.sendSmiley();
            }
          } else if (smileyOnClose !== smiley) {
            await this.sendSmiley();
          }
        }
      }
    } finally {
      this.setState({ isRatingDropdownVisible: false, smileyOnClose: smiley });
    }
  };

  async sendSmiley() {
    const { submitRating } = this.props;
    const { smiley } = this.state;
    await submitRating({ smiley });
  }

  hideUserDropdown = () => {
    this.setState({ isUserDropdownVisible: false });
  };

  hideOptionsDropdown = () => {
    this.setState({ isOptionsDropdownVisible: false });
  };

  hideHelpDropdown = (e) => {
    this.setState({ isHelpOpen: false });
  };

  openRatingDropdown = (event) => {
    const { isRatingDropdownVisible } = this.state;
    if (!isRatingDropdownVisible) {
      document.addEventListener('click', this.checkDocumentClick, true);
      this.setState({ isRatingDropdownVisible: true });
    }
  };

  onChangeSmiley = (rating) => {
    this.setState({ smiley: rating, isRatingDropdownVisible: true });
  };

  onChangeTextArea = (value) => {
    this.setState({ improvements: value, isRatingDropdownVisible: true });
  };

  openOptionsDropdown = () => {
    const { isOptionsDropdownVisible } = this.state;
    if (!isOptionsDropdownVisible) {
      document.addEventListener('click', this.checkDocumentClick, true);
      this.setState({ isOptionsDropdownVisible: true });
    }
  };

  openUserDropdown = () => {
    const { isUserDropdownVisible } = this.state;
    if (!isUserDropdownVisible) {
      document.addEventListener('click', this.checkDocumentClick, true);
      this.setState({ isUserDropdownVisible: true });
    }
  };

  openHelpDropdown = () => {
    const { isHelpOpen } = this.state;
    if (!isHelpOpen) {
      document.addEventListener('click', this.checkDocumentClick, true);
      this.setState({ isHelpOpen: true });
    }
  };

  onToggleCustomerMode = () => {
    const { toggleCustomerModeAction } = this.props;
    this.setState((prevState) => ({
      isOptionsDropdownVisible: true,
    }));

    toggleCustomerModeAction();
  };

  onClose = () => {
    this.setState({ feedBackModalOpen: false });
  };

  async submitImprovements() {
    const { currentRating } = this.props;
    const { improvements, smiley, smileyOnClose } = this.state;

    this.setState({ isSubmittingImprovements: true });
    if (improvements !== '') {
      try {
        await createSupportChatConversation(improvements);
        this.setState({ feedBackModalOpen: true, feedBackSent: true });
      } catch (x) {
        this.setState({ feedBackModalOpen: true, feedBackSent: false });
      }
    }
    if (smiley !== 0 && smiley !== -1) {
      if (smileyOnClose === 0) {
        if (currentRating !== smiley) {
          await this.sendSmiley();
        }
      } else if (smileyOnClose !== smiley) {
        await this.sendSmiley();
      }
    }
    this.setState({
      improvements: '',
      isRatingDropdownVisible: false,
      smileyOnClose: smiley,
      isSubmittingImprovements: false,
    });
  }

  getWeekDay = () => {
    const { garage } = this.props;
    const d = new Date();
    const day = d.getDay();
    const phoneNumber = PHONE_NUMBERS[garage.country] || PHONE_NUMBERS.default;
    switch (day) {
      case 1:
      case 2:
      case 3:
      case 4:
        return polyglot.t('helpModal.openHoursDuringWeek').replace('{{PHONENUMBER}}', phoneNumber);
      case 5:
        return polyglot.t('helpModal.openHoursFriday').replace('{{PHONENUMBER}}', phoneNumber);
      case 6:
      case 0:
        return polyglot.t('helpModal.openHoursWeekend').replace('{{PHONENUMBER}}', phoneNumber);
      default: return '';
    }
  };

  getWeekDayNumber = () => {
    const d = new Date();
    return d.getDay();
  };

  isGarageOlderThan30Days = () => {
    const { subscriptionData } = this.props;
    const trialEnd = moment(subscriptionData.trialEnd);
    const now = moment(new Date());
    return !trialEnd.add(30, 'days').isAfter(now);
  };

  isSetupCashRegisterVisible() {
    const { garage, user, isMobileSize } = this.props;

    if (isMobileSize) {
      return false;
    }

    if (!(hasCashRegisterService(garage) && garage.isInLiveOperation && hasPermission(user, garage, 'setupCashRegister'))
      || (window.config.stripe.enabled && !ACTIVE_STATUSES.includes(garage.subscriptionData.status))
    ) {
      return false;
    }

    if (garage.subscriptionData.addons[ADDON_NAMES.FISKALY_CASH_REGISTER]
      || garage.subscriptionData.addons[ADDON_NAMES.CERTIFICATE]
    ) {
      if (garage.fiskaly && garage.fiskaly.clientId) {
        return false;
      }
      if (garage.credentials && (garage.credentials.name || garage.credentials.username)) {
        return false;
      }
      return true;
    }
    return false;
  }

  // eslint-disable-next-line complexity
  renderSmileyRating() {
    const { user, currentRating } = this.props;
    const {
      smiley,
      isRatingDropdownVisible,
      isSubmittingImprovements,
      feedBackSent,
      feedBackModalOpen,
      improvements,
    } = this.state;

    return (
      <div id="smiley" className={styles.headerSmileyContainer}>
        <InteractableDiv
          className={
            cx(
              styles.headerSmiley,
              { [styles.headerSmileyZoom]: currentRating === 0 },
            )}
          onClick={this.openRatingDropdown}
        >
          {smiley !== 0 && smiley !== -1
            ? Header.smileys[smiley].icon : <SmileyMehHappy/>}
        </InteractableDiv>
        <div
          className={cx(styles.headerDropdownList, styles.headerDropdownRating, {
            [styles.headerDropdownListHidden]: !isRatingDropdownVisible,
          })}
          ref={this.ratingModalRef}
        >
          <div>
            {polyglot.t('header.rating.header')}
          </div>
          <div className={styles.headerDropdownRatingSlider}>
            {Object.keys(Header.smileys).map((index) => {
              const currentSmiley = Header.smileys[index];
              return (
                <InteractableDiv
                  key={currentSmiley.id}
                  className={cx(
                    styles[`headerDropdownRating${currentSmiley.id.charAt(0).toUpperCase()
                    + currentSmiley.id.slice(1)}`],
                  )}
                  onClick={() => this.onChangeSmiley(Number(index))}
                >
                  {React.cloneElement(currentSmiley.icon, { fill: '#E3E6E9' })}
                </InteractableDiv>
              );
            })}
            <div
              className={cx(
                smiley !== 0 && smiley !== -1
                  ? styles.headerDropdownRatingSliderActive
                  : styles.headerDropdownRatingSliderHidden,
                {
                  [styles.headerDropdownRatingSliderSadActive]: smiley === 1,
                  [styles.headerDropdownRatingSliderSadMehActive]: smiley === 2,
                  [styles.headerDropdownRatingSliderMehActive]: smiley === 3,
                  [styles.headerDropdownRatingSliderMehHappyActive]: smiley === 4,
                  [styles.headerDropdownRatingSliderHappyActive]: smiley === 5,
                },
              )}
            >
              {Header.smileys[smiley]?.icon || <SmileyMehHappy/>}
            </div>
            <div className={styles.headerDropdownRatingSliderBar}/>
          </div>
          <div className={styles.headerDropdownRatingSatisfaction}>
            <div>
              {polyglot.t('header.rating.dissatisfied')}
            </div>
            <div>
              {polyglot.t('header.rating.satisfied')}
            </div>
          </div>
          {
            smiley === 0 &&
              <CustomLink className={styles.headerDropdownRatingNoRating} onClick={this.hideRatingDropdown}>{polyglot.t('header.rating.norating')}</CustomLink>
          }
          {smiley !== 0 && smiley !== -1 && (
            <div>
              <div className={styles.headerDropdownRatingThanks}>
                {`${polyglot.t('header.rating.thanks') + user.firstName}!`}
              </div>
              <div className={styles.headerDropdownRatingImprovements}>
                {polyglot.t('header.rating.improvements')}
              </div>
              <TextareaModern
                className={styles.headerDropdownRatingImprovementsText}
                onClick={({ target }) => this.onChangeTextArea(target.value)}
                value={improvements}
                onChange={({ target }) => this.onChangeTextArea(target.value)}
                name="improvements"
                placeholder={polyglot.t('header.rating.improvementsPlaceholder')}
              />
              <ButtonModern
                className={styles.headerDropdownRatingSubmit}
                styleType="add"
                onClick={() => this.submitImprovements()}
                isLoading={isSubmittingImprovements}
              >
                {polyglot.t('header.rating.improvementsSubmit')}
              </ButtonModern>
            </div>
          )}
          <ResponsiveModal
            onClose={this.onClose}
            title={feedBackSent ? `${polyglot.t('header.rating.thanks') + user.firstName}!`
              : polyglot.t('header.rating.feedbackFailure')}
            isOpen={feedBackModalOpen}
            width={370}
          >
            <div className={styles.headerDropdownRatingSubmitPicture}>
              <Icon type="feedbackPicture" width={290} height={300}/>
            </div>
            {feedBackSent && (
              <div className={styles.headerDropdownRatingSubmitText}>
                {polyglot.t('header.rating.feedbackSentText')}
              </div>
            )}
            {!feedBackSent && (
              <div className={styles.headerDropdownRatingSubmitText}>
                {polyglot.t('header.rating.feedbackFailureText')}
              </div>
            )}
            <ButtonModern className={styles.headerDropdownRatingSubmitButton}
                          styleType="add" onClick={this.onClose}>
              {polyglot.t('header.rating.feedbackButton')}
            </ButtonModern>
          </ResponsiveModal>
        </div>
      </div>
    );
  }

  renderHelpButton() {
    const { garage, user } = this.props;
    const { isHelpOpen } = this.state;

    const inTrialOrTrialLessThan30DaysAgo = isInTrial(garage) || !this.isGarageOlderThan30Days();

    const hasAdvancedSupport = SUPPORTED_SUPPORT_LOCALES_ARRAY.includes(user.locale);

    return (
      <div className={cx(styles.headerDropdownHelpWrapper)}>
        <ButtonModern
          className={styles.headerHelpButton}
          onClick={this.openHelpDropdown}
          styleType={ButtonModern.STYLE.ADD}
          dataTest="headerSupportButton"
          size="medium"
        >
          <FontAwesomeIcon icon={faQuestionCircle} className={styles.headerHelpButtonIcon}/>
          <span className={styles.headerHelpButtonText}>
            {polyglot.t('helpModal.help')}
          </span>
        </ButtonModern>
        <div
          className={cx(
            styles.headerDropdownList,
            styles.headerDropdownHelp,
            styles.headerDropdownArrow,
            {
              [styles.headerDropdownListHidden]: !isHelpOpen,
            },
          )}
          dataTest="headerSupportButtonDropdownMenu"
          ref={this.helpModalRef}
        >
          <div className={styles.headerDropdownHelpContent}>
            {hasAdvancedSupport && (
              <>
                <div className={cx(
                  styles.headerDropdownHelpContainer,
                  styles.headerDropdownHelpContainerWithSvg,
                  {
                    [styles.headerDropdownHelpContainerHidden]: false,
                  },
                )}>
                  <div className={styles.headerDropdownHelpContainerWithSvgContent}>
                    <div
                      className={cx(styles.headerTitle, styles.headerDropdownHelpListItem)}
                    >
                      {polyglot.t('helpModal.new')}
                    </div>
                    <div className={styles.headerDropdownHelpListItem}>
                      {polyglot.t('helpModal.showYouEverything')}
                    </div>
                    <div>
                      <ButtonModern
                        withIcon styleType="add"
                        className={styles.headerDropdownHelpButton} onClick={() => {
                          window.open('https://easywerkstatt.com/webinar', '_blank');
                        }}>
                        <FontAwesomeIcon
                          icon={faCalendarDay}
                          className={styles.headerHelpButtonIcon}
                        />
                        {polyglot.t('helpModal.registerAppointment')}
                      </ButtonModern>
                    </div>
                  </div>
                  <div className={styles.headerDropdownWebinar}>
                    <Webinar/>
                  </div>
                </div>
                <div className={styles.headerDropdownHelpContainer}>
                  <div className={cx(styles.headerTitle, styles.headerDropdownHelpListItem)}>
                    {polyglot.t('helpModal.specificQuestion')}
                  </div>
                  <div className={styles.headerDropdownHelpListItem}>
                    {polyglot.t('helpModal.findAnswersHere')}
                  </div>
                  <div>
                    <ButtonModern
                      styleType={inTrialOrTrialLessThan30DaysAgo ? 'cancel' : 'add'}
                      size={inTrialOrTrialLessThan30DaysAgo ? 'medium' : 'default'}
                      onClick={() => {
                        showSupportSpace('help');
                        this.hideHelpDropdown();
                      }}
                      className={styles.headerDropdownHelpButton}
                      dataTest="headerSupportButtonDropdownMenuChat"
                      withIcon
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        className={styles.headerHelpButtonIcon}
                      />
                      {polyglot.t('helpModal.searchHelp')}
                    </ButtonModern>
                  </div>
                </div>
                <div className={styles.headerDropdownHelpContainer}>
                  <div className={cx(styles.headerTitle, styles.headerDropdownHelpListItem)}>
                    {polyglot.t('helpModal.nothingFound')}
                  </div>
                  <div className={styles.headerDropdownHelpListItem}>
                    {polyglot.t('helpModal.contactUs')}
                  </div>
                  <div>
                    <ButtonModern withIcon styleType="cancel" size="medium" onClick={() => {
                      showSupportSpace('messages');
                      this.hideHelpDropdown();
                    }} className={styles.headerDropdownHelpButton}>
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className={styles.headerHelpButtonIcon}
                      />
                      {polyglot.t('helpModal.contactUsButton')}
                    </ButtonModern>
                  </div>
                </div>
              </>
            )}
            <div className={styles.headerDropdownHelpContainer}>
              <div className={cx(styles.headerTitle, styles.headerDropdownHelpListItem)}>
                {hasAdvancedSupport
                  ? polyglot.t('helpModal.questionComplicated')
                  : polyglot.t('helpModal.specificQuestion')}
              </div>
              <div className={styles.headerDropdownHelpListItem}>
                {this.getWeekDay()}
              </div>
              <div className={styles.headerDropdownHelpAccordionContainer}>
                <Accordion
                  styleType="noStyle"
                  className={styles.headerDropdownHelpAccordion}
                  headerClassName={styles.headerDropdownHelpAccordionHeader}
                  title={polyglot.t('helpModal.openHours')}
                >
                  <div>
                    {Header.openHours.map(({ id }, index) => (
                      <div
                        key={id}
                        className={cx(
                          styles.headerDropdownHelpAccordionItems,
                          {
                            [styles.headerDropdownHelpAccordionItemsCurrent]:
                            this.getWeekDayNumber() === index + 1,
                          },
                        )}
                      >
                        <div className={styles.headerDropdownOpenHours}>
                          {polyglot.t(`helpModal.${id}`)}
                        </div>
                        <div>
                          {polyglot.t(`helpModal.${index < 4 ? 'duringWeek' : 'duringWeekend'}`)}
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion>
                <div className={styles.headerDropdownHelpListItem}>
                  <a
                    className={cx(
                      buttonStyles.buttonModern,
                      buttonStyles.buttonModernCancel,
                      styles.headerDropdownHelpDownload,
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://get.teamviewer.com/67ygcsa"
                  >
                    {polyglot.t('helpModal.downloadRemote')}
                    <FontAwesomeIcon icon={faChevronRight}/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOptionsButton() {
    const {
      roles,
      history,
      garage,
      isCustomerMode,
      mobileViewOption,
      isMobileSize,
    } = this.props;
    const { isOptionsDropdownVisible } = this.state;

    const menuItems = getDropdownMenuItems(roles, history, garage);

    return (
      <div className={cx(styles.headerDropdown)}>
        <ButtonModern
          className={cx(buttonStyles.buttonModernConfig, styles.headerDropdownButton)}
          type="button"
          dataTest="headerOptionsButton"
          onClick={this.openOptionsDropdown}
        >
          <FontAwesomeIcon icon={faCog} className={styles.headerDropdownButtonIcon}/>
          {polyglot.t('header.options')}
        </ButtonModern>
        <InteractableDiv
          className={styles.headerDropdownButtonSmall}
          type="button"
          onClick={this.openOptionsDropdown}
        >
          <FontAwesomeIcon icon={faCog}/>
        </InteractableDiv>
        <div
          className={cx(styles.headerDropdownList, styles.headerDropdownOptions, {
            [styles.headerDropdownListHidden]: !isOptionsDropdownVisible,
          })}
          dataTest="headerOptionDropdownMenu"
          ref={this.optionsModalRef}
        >
          {(roles.includes('admin') || roles.includes('office')) && (
            <>
              <div
                className={styles.customerMode}
                dataTest="headerOptionDropdownMenuCustomerMode"
              >
                <Switch
                  name="customerMode"
                  className={styles.switchMargin}
                  label={polyglot.t('header.customerMode')}
                  value={isCustomerMode}
                  onChange={this.onToggleCustomerMode}
                  dataTest="headerOptionsSwitch"
                />
              </div>

              <div className={styles.headerDropdownListSeparator}/>
            </>
          )}
          {menuItems.map(({
            onClick, title, icon, enabledOnMobile, hiddenOnApplePWA,
          }) => !(!enabledOnMobile && isMobileSize && mobileViewOption === 'optionOne')
            && !(hiddenOnApplePWA && DeviceService.isPWA && DeviceService.isAppleDevice)
            && (
              <button
                className={styles.headerDropdownListItem}
                key={title}
                type="button"
                onClick={(e) => {
                  onClick(e);
                  this.hideOptionsDropdown();
                }}
                dataTest={`headerOptionDropdownMenu${title}`}
              >
                <FontAwesomeIcon icon={icon} className={styles.headerDropdownListItemIcon}/>
                <div className={styles.headerDropdownText}>
                  {`${title}`}
                </div>
              </button>
            ))}
        </div>
      </div>
    );
  }

  renderUserButton() {
    const {
      user,
      currentGarageId,
      garages = [],
      changeGarage,
    } = this.props;
    const {
      isUserDropdownVisible,
    } = this.state;

    return (
      <InteractableDiv
        className={styles.headerUser}
        onClick={this.openUserDropdown}
        dataTest="headerUserButton"
      >
        {user.lastName.charAt(0) + user.firstName.charAt(0)}
        <div
          ref={this.userModalRef}
          className={cx(styles.headerDropdownList, styles.headerUserDropdown, {
            [styles.headerDropdownListHidden]: !isUserDropdownVisible,
          })}
        >
          <div
            className={cx(styles.headerDropdownListItem, styles.headerDropdownListItemText)}
          >
            <div className={cx(styles.headerUser, styles.headerUserInitials)}>
              {user.lastName.charAt(0) + user.firstName.charAt(0)}
            </div>
            <div className={styles.headerDropdownText}>
              {`${user.lastName} ${user.firstName}`}
            </div>
          </div>
          <div className={styles.headerDropdownListSeparator} />
          {garages.map((garageItem) => (
            <button
              type="button"
              dataTest={`headerUser${garageItem.garageName}`}
              key={garageItem.garageId}
              className={cx(styles.headerDropdownListItem, {
                [styles.headerDropdownListItemActive]:
                currentGarageId === garageItem.garageId,
              })}
              onClick={() => changeGarage(garageItem.garageId)}
            >
              <FontAwesomeIcon icon={faTools} className={styles.headerDropdownListItemIcon} />
              <div className={styles.headerDropdownText}>
                {garageItem.garageName}
              </div>
            </button>
          ))}
          <div className={styles.headerDropdownListSeparator} />
          <a className={styles.headerDropdownListItem} href="/signout" dataTest="headerUserDropdownLogout">
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className={styles.headerDropdownListItemIcon}
            />
            <div className={styles.headerDropdownText}>
              {polyglot.t('header.settingsOptions.logOut')}
            </div>
          </a>
        </div>
      </InteractableDiv>
    );
  }

  render() {
    const {
      history,
      garage,
      isMobileSize,
    } = this.props;

    const { headerColor } = garage;

    const headerColorStyle = headerColor ? { background: headerColor } : {};

    return (
      <Fragment>
        <div className={styles.header} style={headerColorStyle}>
          <div className={styles.headerLeft}>
            <div dataTest={'headerTitle'} className={styles.headerMenu}>
              {garage.title}
            </div>
          </div>
          <div className={styles.headerIcons}>
            {this.isSetupCashRegisterVisible() && (
              <ButtonModern
                onClick={() => history.push(garage.country === 'AT' ? '/cashRegisterSettings'
                  : '/cashSettings/cashRegister')}
                className={cx(buttonStyles.buttonModernAdd, styles.headerActionButton)}
                size="by-content"
              >
                {polyglot.t('header.setupCashRegister')}
              </ButtonModern>
            )}
          </div>

          <div className={styles.headerRight}>
            {!isMobileSize && this.renderSmileyRating()}
            <div className={styles.headerNotification} dataTest="headerNotificationButton">
              <Notifications history={history}/>
            </div>
            {!isMobileSize && this.renderHelpButton()}
            {this.renderOptionsButton()}
            {this.renderUserButton()}
          </div>
          {/* <SnowScreen
            maxParticles={40}
            addBlanket={false}
            maxWindTravelPercent={2}
            snowTravelDuration={1.2}
          /> */}
        </div>
      </Fragment>
    );
  }
}
