import { faFileCircleXmark } from 'fontawesome/pro-solid-svg-icons';
import { replaceToBoldObj } from 'helpers/locale';
import { INVOICE_STATUSES } from 'shared-library/src/definitions/invoice';
import React from 'react';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';

export class SmsPickupNotificationError {
  constructor(notification, { history }) {
    this.notification = notification;
    this.history = history;
  }

  Icon({ className }) {
    return <FontAwesomeIcon
      icon={faFileCircleXmark}
      className={className}
    />;
  }

  getInvoiceStatus = (invoiceType) => {
    switch (invoiceType) {
      case INVOICE_STATUSES.PAID:
      case INVOICE_STATUSES.OPEN:
        return 'invoice';
      default:
        return invoiceType;
    }
  };

  getNotificationText(polyglot) {
    return replaceToBoldObj(polyglot.t('notifications.smsPickupNotificationErrorText', {
      invoiceNumber: this.notification.metaData.invoiceNumber || '',
      invoiceStatus: polyglot.t(`invoices.statuses.${this.getInvoiceStatus(this.notification.metaData.invoiceStatus)}`),
    }));
  }

  onClickAction() {
    this.history.push(`/invoices/view/${this.notification.metaData.invoiceId}`);
  }
}
