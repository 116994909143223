let _width = 0;
let _height = 0;
let _isMobile = false;
let _OS = 'Unknown';
let _browser = 'Unknown';
let _isTouchDevice = false;
let _isPWA = false;
let _isAppleDevice = false;
const _mobileSize = 900;
const resizeSubscribers = [];
let resizeDebounceTimeout = null;

const OS_LIST = Object.freeze({
  WINDOWS: 'Windows',
  MAC_OS: 'MacOS',
  UNIX: 'UNIX',
  LINUX: 'Linux',
  I_OS: 'iOS',
  ANDROID: 'Android',
});

const BROWSER_LIST = Object.freeze({
  MICROSOFT_EDGE: 'Microsoft Edge',
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  SAFARI: 'Safari',
  OPERA: 'Opera',
  INTERNET_EXPLORER: 'Internet Explorer',
});

const getIsPWA = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSourceHasHomescreen = urlParams.get('utm_source') === 'homescreen';
  const mediaqQueryStandalone = '(display-mode: standalone)';
  const isDisplayModeStandalone = navigator.standalone
    || window.matchMedia(mediaqQueryStandalone).matches;

  _isPWA = utmSourceHasHomescreen || isDisplayModeStandalone;
  return _isPWA;
};

getIsPWA();

const fireResizeCallback = (callback) => {
  callback({ width: _width, height: _height });
};

const fireResizeCallbacks = () => {
  resizeSubscribers.forEach((callback) => fireResizeCallback(callback));
};

const getWindowWidthAndHeight = () => {
  if (_OS === 'iOS') {
    _width = window.screen.width;
    _height = window.screen.height;
  } else {
    _width = window.innerWidth;
    _height = window.innerHeight;
  }
  fireResizeCallbacks();
};

const getIsMobile = () => {
  // eslint-disable-next-line
  const info = navigator.userAgent||navigator.vendor||window.opera;
  _isMobile = (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(info) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(info.substr(0, 4)));
};

const getIsTouch = () => {
  _isTouchDevice = (
    // eslint-disable-next-line no-restricted-syntax
    ('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0)
  );
};

const getIsAppleDevice = () => {
  _isAppleDevice = _OS === OS_LIST.MAC_OS || _OS === OS_LIST.I_OS;
};

const getOS = () => {
  if (navigator.appVersion.indexOf('Win') !== -1) _OS = OS_LIST.WINDOWS;
  if (navigator.appVersion.indexOf('Mac') !== -1) _OS = OS_LIST.MAC_OS;
  if (navigator.appVersion.indexOf('X11') !== -1) _OS = OS_LIST.UNIX;
  if (navigator.appVersion.indexOf('Linux') !== -1) _OS = OS_LIST.LINUX;
  if (/iPad|iPhone|iPod/.test(navigator.platform)) _OS = OS_LIST.I_OS;
  if (/(android)/i.test(navigator.userAgent)) _OS = OS_LIST.ANDROID;
};

const getBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.indexOf('Edg') > -1) _browser = BROWSER_LIST.MICROSOFT_EDGE;
  if (userAgent.indexOf('Safari') > -1) _browser = BROWSER_LIST.SAFARI;
  if (userAgent.indexOf('Chrome') > -1) _browser = BROWSER_LIST.CHROME;
  if (userAgent.indexOf('Firefox') > -1) _browser = BROWSER_LIST.FIREFOX;
  if (userAgent.indexOf('Opera') > -1) _browser = BROWSER_LIST.OPERA;
  if (userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1) _browser = BROWSER_LIST.INTERNET_EXPLORER;
};

const initData = () => {
  getOS();
  getBrowser();
  getIsAppleDevice();
  getIsMobile();
  getIsTouch();
  getWindowWidthAndHeight();
};

initData();

const updateData = () => {
  getIsTouch();
  getIsMobile();
  getWindowWidthAndHeight();
};

// Timeouts for iOS / iPad
setTimeout(updateData, 200);
setTimeout(updateData, 2000);

window.addEventListener('resize', (event) => {
  if (resizeDebounceTimeout) {
    clearTimeout(resizeDebounceTimeout);
  }
  resizeDebounceTimeout = setTimeout(() => {
    resizeDebounceTimeout = null;
    updateData();
  }, 10);
});

export default {
  addResizeListener(callback) {
    resizeSubscribers.push(callback);
    fireResizeCallback(callback);

    return {
      unsubscribe: () => {
        const objIndex = resizeSubscribers.indexOf(callback);
        if (objIndex >= 0) {
          resizeSubscribers.splice(objIndex, 1);
        }
      },
    };
  },
  OS_LIST,
  BROWSER_LIST,
  get width() {
    return _width;
  },
  get height() {
    return _height;
  },
  get isMobile() {
    return _isMobile;
  },
  get OS() {
    return _OS;
  },
  get browser() {
    return _browser;
  },
  get mobileSize() {
    return _mobileSize;
  },
  get isMobileSize() {
    return _width <= _mobileSize;
  },
  get isTouchDevice() {
    return _isTouchDevice;
  },
  get isPWA() {
    return _isPWA;
  },
  get isAppleDevice() {
    return _isAppleDevice;
  },
};
