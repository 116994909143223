import PropTypes from 'prop-types';

export const DATE_FORMAT_MOMENT = 'DD.MM.YYYY';
export const DATE_FORMAT = 'dd.MM.yyyy';

export const PHONE_NUMBERS = {
  AT: '+43 7719 20304',
  DE: '+49 851 205 499 07',
  CH: '+41 71 588 07 99',
  IT: '+39 0471 166 0689',
  default: '+43 7719 20304',
};

export const IMAGE_EXTENSIONS = ['tif', 'jpg', 'jpeg', 'gif', 'png', 'pdf'];

export const PDF_SHAPE = {
  _id: PropTypes.string,
  startedUploadOn: PropTypes.date,
  finishedUploadOn: PropTypes.date,
  version: PropTypes.number,
};

export const INVOICE_DUNNING_STATUSES = [
  'none',
  'reminder',
  'demandLetter1',
  'demandLetter2',
  'demandLetter3',
];
