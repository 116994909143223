import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import TableLoading from 'components/common/loading/components/tableLoading/tableLoading';

import tableStyles from 'styles/table.pcss';

export default class InfiniteBlock extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onScroll: PropTypes.func.isRequired,
    className: PropTypes.string,
    isDataLoading: PropTypes.bool,
    setHighlightedIndex: PropTypes.func,
    dataTest: PropTypes.string,
    onMouseLeave: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    children: null,
    isDataLoading: false,
    setHighlightedIndex: () => null,
    dataTest: '',
    onMouseLeave: () => null,
  };

  state = {
    isLoadingOnScroll: false,
    index: -1,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.respondToUserInput);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.respondToUserInput);
  }

  onScroll = () => {
    const { isLoadingOnScroll } = this.state;
    const { scrollTop, offsetHeight, scrollHeight } = this.tableBody;
    // when user uses zoom, browsers calculate scrollTop with error
    // that's why fetch if diffrent between scrollTop+offsetHeight and scrollHeight less when 5
    if (!(Math.abs((scrollTop + offsetHeight) - scrollHeight) <= 5)) {
      return;
    }

    if (!isLoadingOnScroll) {
      this.setState({ isLoadingOnScroll: true });
      this.props.onScroll()
        .then(() => this.setState({ isLoadingOnScroll: false }));
    }
  };

  respondToUserInput = (event) => {
    setTimeout(() => {
      if (!this.tableBody) return;
      const { children, setHighlightedIndex } = this.props;
      const { scrollHeight, childElementCount } = this.tableBody;
      const { key } = this.state;
      let { index } = this.state;

      const lineHeight = scrollHeight / childElementCount;

      switch (event.key) {
        case 'ArrowDown': index = this.addToIndex(1, index); break;
        case 'ArrowUp': index = this.addToIndex(-1, index); break;
        default: for (let i = 0; i < children.length; i += 1) {
          if (this.childStartsWithKey(children[i], event.key)) {
            if (index >= i && key === event.key) {
              // eslint-disable-next-line no-continue
              continue;
            }
            index = i;
            break;
          }
        }
      }

      this.setState({
        index,
        key: event.key,
      });

      if (index >= 0) {
        this.tableBody.scrollTop = index * lineHeight;
        setHighlightedIndex(index);
      }
    }, 1);
  };

  childStartsWithKey = (child, key) => {
    let tempChild = child;
    if (!tempChild?.props?.children) return false;
    while (typeof tempChild.props.children === 'object') {
      tempChild = tempChild.props.children;
      if (Array.isArray(tempChild)) {
        break;
      }
    }

    if (Array.isArray(tempChild)) {
      if (!tempChild[0].props?.children) {
        for (let i = 0; i < tempChild.length; i += 1) {
          if (typeof tempChild[i] === 'string' && tempChild[i].trim().length > 0) {
            return tempChild[i].toLowerCase().startsWith(key);
          }
        }
      }
      return tempChild[0].props.children.toString().toLowerCase().startsWith(key);
    }
    return tempChild.props.children.toString().toLowerCase().startsWith(key);
  };

  addToIndex(val, index) {
    const { children } = this.props;
    if (!children) return 0;
    const newIndex = index + val;

    if (newIndex === children.length) { return 0; }
    if (newIndex < 0) { return children.length - 1; }
    return newIndex;
  }

  render() {
    const { isLoadingOnScroll } = this.state;
    const {
      children, className, isDataLoading, dataTest, onMouseLeave,
    } = this.props;

    return (
      <div
        className={cx(tableStyles.tableBody, className)}
        onScroll={this.onScroll}
        ref={(tableBody) => { this.tableBody = tableBody; }}
        dataTest={dataTest}
        onMouseLeave={onMouseLeave}
      >
        {isDataLoading ? <TableLoading /> : children}
        {isLoadingOnScroll && <TableLoading isScroll />}
      </div>
    );
  }
}
