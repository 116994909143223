import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  faCheckCircle, faExclamationCircle, faInfoCircle, faTimer,
} from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import cx from 'classnames';
import { faPartyHorn } from 'fontawesome/pro-regular-svg-icons';
import styles from './customStatusLabel.styles.pcss';

class CustomStatusLabel extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    status: PropTypes.string,
    alignIconEnd: PropTypes.bool,
    isBold: PropTypes.bool,
    dataTest: PropTypes.string,
    className: PropTypes.string,
    textClassName: PropTypes.string,
    icon: PropTypes.object,
  };

  static defaultProps = {
    title: '',
    text: '',
    status: 'success',
    alignIconEnd: false,
    isBold: false,
    dataTest: '',
    className: '',
    textClassName: '',
    icon: null,
  };

  static state = {
  };

  getIcon = () => {
    const { status, icon: propIcon } = this.props;
    if (propIcon) return propIcon;
    let icon = faCheckCircle;
    switch (status) {
      case 'success':
        icon = faCheckCircle;
        break;
      case 'warning':
        icon = faExclamationCircle;
        break;
      case 'error':
        icon = faExclamationCircle;
        break;
      case 'birthday':
        icon = faPartyHorn;
        break;
      case 'info':
        icon = faInfoCircle;
        break;
      case 'planned':
        icon = faTimer;
        break;
      default:
        icon = faCheckCircle;
    }
    return icon;
  };

  render() {
    const {
      status,
      title,
      text,
      alignIconEnd,
      isBold,
      dataTest,
      className,
      textClassName,
    } = this.props;
    const icon = this.getIcon();

    return (
      <div
        style={isBold ? { fontWeight: 500 } : { fontWeight: 400 }}
        className={cx(styles.label, styles[status], className)}
      >
        {!alignIconEnd && (
          <FontAwesomeIcon icon={icon} />
        )}
        <div className={alignIconEnd ? styles.labelTitleRight : styles.labelTitleLeft}>
          {title}
        </div>
        <div dataTest={dataTest} className={cx(styles.labelText, textClassName)}>
          {text}
        </div>
        {alignIconEnd && (
          <FontAwesomeIcon icon={icon} />
        )}
      </div>
    );
  }
}

export default CustomStatusLabel;
