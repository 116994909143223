import moment from 'moment';

import { DATE_FORMAT_MOMENT } from 'constants.js';

export function toUTCDay(date) {
  return moment.utc(date, (typeof date === 'string' && date.match(/\d{2}\.\d{2}\.\d{2}/gi) ? 'DD.MM.YY' : undefined)).format(DATE_FORMAT_MOMENT);
}

export function isValidDate(date) {
  return moment(date, DATE_FORMAT_MOMENT).isValid();
}

export function toTime(date) {
  return moment(date).format('DD.MM.YYYY HH:mm');
}

export const getCorrectDate = (date) => {
  // DD.MM.YYYY contains 10 symbols
  if (typeof date === 'string' && date.length === 10) {
    return date;
  }

  return date ? toUTCDay(date) : '';
};

export const parseDateString = (dateString) => {
  if (!dateString) {
    return null;
  }
  const parsedDate = moment(dateString);
  if (!parsedDate || parsedDate === 'Invalid Date') {
    return null;
  }
  return parsedDate;
};

export const getServerCorrectDate = (date) => {
  return date ? moment.utc(getCorrectDate(date), DATE_FORMAT_MOMENT) : null;
};
