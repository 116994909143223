const INVOICE_STATUSES = Object.freeze({
  DRAFT: 'draft',
  PREVIEW: 'preview',
  INTERNAL: 'internal',
  OPEN: 'open',
  PAID: 'paid',
  CANCELLING: 'cancelling',
  PAID_CREDIT: 'paidCredit',
});

const INVOICE_FINALE_STATUSES = [
  INVOICE_STATUSES.OPEN,
  INVOICE_STATUSES.PAID,
  INVOICE_STATUSES.PAID_CREDIT,
];

function getPdfTypeForStatus(status) {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.INTERNAL, INVOICE_STATUSES.PREVIEW].includes(
    status,
  )
    ? status
    : 'invoice';
}

function isFinalStatus(status) {
  return [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.PAID, INVOICE_STATUSES.PAID_CREDIT].includes(
    status,
  );
}

module.exports = {
  INVOICE_STATUSES,
  INVOICE_FINALE_STATUSES,
  getPdfTypeForStatus,
  isFinalStatus,
};
