const PRODUCT_TYPES = Object.freeze({
  WORK: 'work',
  PRODUCT: 'product',
  OTHER: 'other',
  CALCULATED: 'calculated',
  GROUP: 'group',
});

function isGroup(product) {
  return product && product.type === PRODUCT_TYPES.GROUP;
}

function excludeFromVatCalculation(product) {
  return (
    !product ||
    ([PRODUCT_TYPES.GROUP, PRODUCT_TYPES.CALCULATED].includes(product.type) &&
      !product.positionPrice)
  );
}

module.exports = {
  PRODUCT_TYPES,
  isGroup,
  excludeFromVatCalculation,
};
