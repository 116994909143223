import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './optionPicker.styles.pcss';

export default function OptionPicker({
  onChange,
  width,
  height,
  options,
  selectedOption,
  modern,
  className: customStyle,
  dataTest,
  ...props
}) {
  const optionsPickerStyle = { width, height };
  const selectedOptionIndex = options
    .indexOf(options.find((option) => option.id === selectedOption));
  let sliderStyle;
  if (modern) {
    sliderStyle = { width: `${100 / options.length}%`, left: `${((100 / options.length) * selectedOptionIndex)}%`, height: '100%' };
  } else {
    sliderStyle = { width: `${(100 - 4 * options.length) / options.length}%`, left: `${2 + ((100 / options.length) * selectedOptionIndex)}%`, height: '75%' };
  }
  return (
    <div
      className={cx(styles.optionPickerContainer, modern ? styles.modern : null, customStyle)}
      style={optionsPickerStyle}
    >
      <div className={styles.slider} style={sliderStyle} />
      <div className={styles.optionsContainer} dataTest={dataTest}>
        {options.map((option) => {
          return (
            <div
              key={option.id}
              className={cx(
                styles.option,
                modern && option.id === selectedOption ? styles.selected : null,
              )}
              onClick={() => onChange(option.id)}
              tabIndex={option.id === selectedOption ? -1 : 0}
              onKeyDown={({ key }) => {
                if (key === 'Enter') onChange(option.id);
              }}
              role="button"
              dataTest={`${dataTest}${option.id}`}
            >
              {option.label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

OptionPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  modern: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  })),
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dataTest: PropTypes.string,
};

OptionPicker.defaultProps = {
  className: '',
  width: '100%',
  height: '100%',
  options: [],
  selectedOption: '',
  modern: false,
  dataTest: '',
};
