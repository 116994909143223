import * as apiClient from 'resources/api.client';

export function fetchVehicles(garageId, data) {
  return apiClient.get('vehicles', { garageId, ...data });
}

export function fetchVehiclesByClientId(garageId, data) {
  return apiClient.get('vehicles/clientId', { garageId, ...data });
}

export function fetchVehiclesInspections(garageId, data) {
  return apiClient.get('vehicles/getInspections', { garageId, ...data });
}

export function fetchVehicle(garageId, id, data) {
  return apiClient.get(`vehicles/${id}`, { garageId, ...data });
}

export function createVehicle(garageId, data) {
  return apiClient.post('vehicles', { garageId }, data);
}

export function removeVehicles(garageId, vehicleIds) {
  return apiClient.post('vehicles/remove', { garageId }, { vehicleIds });
}

export function updateVehicle(garageId, vehicleId, data) {
  return apiClient.put(`vehicles/${vehicleId}`, { garageId }, data);
}

export function calculateDateOfNextInspectionForCountry(garageId, vehicleId, country) {
  return apiClient.put(`vehicles/${vehicleId}/updateDateOfNextInspectionForCountry`, { garageId, country });
}

export function updateMileage(vehicleId, garageId, data) {
  return apiClient.post(`vehicles/${vehicleId}/mileage`, { garageId }, data);
}

export function updateInspection(vehicleId, garageId, data) {
  return apiClient.post(`vehicles/${vehicleId}/inspection`, { garageId }, data);
}

export function getVehiclesCSV(garageId, data) {
  return apiClient.get('vehicles/csv', { garageId, ...data });
}

export function uploadVehicleImage(garageId, vehicleId, data) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });

  return apiClient.postRaw(`vehicles/${vehicleId}/files`, { garageId }, body);
}

export function removeVehicleImages(garageId, vehicleId, filesToRemove) {
  return apiClient.post(`vehicles/${vehicleId}/files/remove`, { garageId }, { filesToRemove });
}

export function updateVehicleImage(garageId, vehicleId, imageId, data) {
  return apiClient.put(`vehicles/${vehicleId}/files`, { garageId, fileId: imageId }, data);
}

export function restoreVehicle(garageId, data) {
  return apiClient.put(`vehicles/${data.vehicleId}/restore`, { garageId }, data);
}
