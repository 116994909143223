import * as apiClient from 'resources/api.client';

export function fetchClients(garageId, data) {
  return apiClient.get('clients', { garageId, ...data });
}

export function fetchClientsBirthdayList(garageId, data) {
  return apiClient.get('clients', { isBirthList: true, garageId, ...data });
}

export function fetchSameClients(garageId, data) {
  return apiClient.get('clients/similar', { garageId, ...data });
}

export function fetchClient(garageId, id, data) {
  return apiClient.get(`clients/${id}`, { garageId, ...data });
}

export function createClient(garageId, data) {
  return apiClient.post('clients', { garageId }, data);
}

export function createClientWithDocument(garageId, file) {
  const body = new FormData();
  body.append('file', file);

  return apiClient.postRaw('clients/create-with-document', { garageId }, body);
}

export function createClientWithDocumentConfirmation(garageId, data) {
  return apiClient.post('clients/create-with-document-confirmation', { garageId }, data);
}

export function removeClients(garageId, clientIds) {
  return apiClient.post('clients/remove', { garageId }, { clientIds });
}

export function updateClient(garageId, data) {
  return apiClient.put(`clients/${data._id}`, { garageId }, data);
}

export function mergeClients(garageId, clientId, oldClientId) {
  return apiClient.put(`clients/${clientId}/merge/${oldClientId}`, { garageId }, {});
}

export function updateClientMessagePdf(garageId, clientId, idx, titel, message) {
  return apiClient.post(`clients/${clientId}/messagePdf/${idx}`, { garageId }, { titel, message });
}

export function removeClientMessagePdf(garageId, clientId, idx) {
  return apiClient.del(`clients/${clientId}/messagePdf/${idx}`, { garageId });
}

export function createClientMessagePdf(garageId, clientId, titel, message) {
  return apiClient.post(`clients/${clientId}/messagePdf`, { garageId }, { titel, message });
}

export function getClientMessagePdf(garageId, clientId, id) {
  return apiClient.get(`clients/${clientId}/messagePdf/${id}`, { garageId });
}

export function getClientsCSV(garageId) {
  return apiClient.get('clients/csv', { garageId });
}

export function getClientsBMDCSV(garageId) {
  return apiClient.get('clients/bmd-csv', { garageId });
}

export function getClientsDatevCSV(garageId) {
  return apiClient.get('clients/datev-csv', { garageId });
}

export function getClientsBirthdayListCSV(garageId, selectedMonth) {
  return apiClient.get('clients/birthday-list/csv', { garageId, selectedMonth });
}

export function uploadClientImage(garageId, clientId, data) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });

  return apiClient.postRaw(`clients/${clientId}/files`, { garageId }, body);
}

export function removeClientImages(garageId, clientId, filesToRemove) {
  return apiClient.post(`clients/${clientId}/files/remove`, { garageId }, { filesToRemove });
}

export function updateClientImage(garageId, clientId, imageId, data) {
  return apiClient.put(`clients/${clientId}/files`, { garageId, fileId: imageId }, data);
}

export function restoreClient(garageId, data) {
  return apiClient.put(`clients/${data.clientId}/restore`, { garageId }, data);
}
