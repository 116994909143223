import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import InteractableDiv from 'components/common/interactableDiv';
import cx from 'classnames';
import styles from './notificationsCard.styles.pcss';
import { getTypeImpl } from '../../types';

class NotificationsCard extends Component {
  static propTypes = {
    notification: PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      createdOn: PropTypes.string,
      metaData: PropTypes.shape({
        invoiceId: PropTypes.string,
      }),
    }).isRequired,
    onClick: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isProcessed: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    isProcessed: false,
  };

  onCardClick = (typeImpl) => {
    const { onClick, notification } = this.props;

    onClick(notification._id);
    typeImpl.onClickAction();
  };

  getFormatedDateString = (date) => {
    const momentDate = moment(date).startOf('day');
    const timeDiff = moment().startOf('day').diff(momentDate, 'days');
    const dateFormatString = timeDiff >= 2 ? 'DD.MM.YY HH:mm' : 'HH:mm';
    const formatedDate = moment(date).format(dateFormatString);
    // eslint-disable-next-line no-nested-ternary
    const dayAsText = timeDiff < 1
      ? polyglot.t('notifications.today')
      : timeDiff < 2
        ? polyglot.t('notifications.yesterday') : '';
    return `${dayAsText} ${formatedDate}`;
  };

  render() {
    const { notification, history, isProcessed } = this.props;
    const typeImpl = getTypeImpl(notification, { history });
    const { Icon } = typeImpl;
    return (
      <InteractableDiv
        className={cx(styles.containerCard, { [styles.containerCardProcessed]: isProcessed })}
        dataTest={`notification-card-${notification._id}`}
        onClick={() => this.onCardClick(typeImpl)}
      >
        <div className={styles.containerCardData}>
          <Icon className={cx(
            styles.containerCardDataIcon,
            { [styles.containerCardDataIconProcessed]: isProcessed },
          )} />
          <div className={styles.containerCardDataText}>
            {typeImpl.getNotificationText(polyglot, notification)}
          </div>
          <div className={styles.containerCardDataTimestamp}>
            {this.getFormatedDateString(notification.createdOn)}
          </div>
        </div>
      </InteractableDiv>
    );
  }
}

export default withDeviceInfo(NotificationsCard);
