import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import { Virtuoso } from 'react-virtuoso';
import styles from './lazyLoadingList.styles.pcss';
import TableLoading from '../loading/components/tableLoading';

class LazyLoadingList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    fetchItems: PropTypes.func.isRequired,
    renderRow: PropTypes.func.isRequired,
    resetState: PropTypes.func,
    hasMore: PropTypes.bool.isRequired,
    rowHeight: PropTypes.number,
    searchText: PropTypes.string,
    emptyContent: PropTypes.node,
    notFoundContent: PropTypes.node,
    sortBy: PropTypes.arrayOf(PropTypes.string),
    sortDirection: PropTypes.number,
    range: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    changeFilter: PropTypes.func,
    onScrollbarWidthChange: PropTypes.func,
    additionalContent: PropTypes.node,
    useTableStyles: PropTypes.bool,
    additionalSortByElement: PropTypes.arrayOf(PropTypes.string),
    maxHeight: PropTypes.string,
    isMobileSize: PropTypes.bool.isRequired,
    additionalLoadingProp: PropTypes.bool,
    onLoadingStateChange: PropTypes.func,
    height: PropTypes.number.isRequired,
  };

  static defaultProps = {
    rowHeight: null,
    searchText: '',
    emptyContent: null,
    notFoundContent: null,
    sortBy: ['draftNumber'],
    sortDirection: 1,
    changeFilter: () => null,
    resetState: () => null,
    onScrollbarWidthChange: () => null,
    additionalContent: null,
    useTableStyles: false,
    additionalSortByElement: [],
    maxHeight: 0,
    additionalLoadingProp: false,
    onLoadingStateChange: () => null,
    range: { from: '', to: '' },
  };

  state = {
    isListLoading: true,
    maxHeight: this.props.maxHeight,
    listTop: 0,
    listRight: 0,
    scrollBarWidth: 0,
  };

  componentDidMount() {
    const { resetState } = this.props;
    resetState();
    this.changeFilter();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchText,
      sortBy,
      sortDirection,
      additionalSortByElement,
      range,
      height,
    } = this.props;
    const {
      listTop,
      listRight,
      lastCalcHeight,
    } = this.state;
    if (prevProps.searchText !== searchText) {
      this.changeFilterDebounce();
    }
    if (prevProps.sortDirection !== sortDirection
      || prevProps.sortBy !== sortBy
      || prevProps.additionalSortByElement !== additionalSortByElement
      || prevProps.range !== range
    ) {
      this.changeFilter();
    }
    const { top, right } = this.listContainerRef.current.getBoundingClientRect();
    // Only recalc on major changes, otherwise we could get a didUpdate loop
    if (Math.abs(listTop - top) > 10
    || Math.abs(listRight - right) > 10
    || Math.abs(lastCalcHeight - height) > 10) {
      this.calcHeight();
    }
  }

  listContainerRef = React.createRef();

  changeFilter = async () => {
    const {
      searchText,
      sortBy,
      sortDirection,
      range,
      changeFilter,
      resetState,
    } = this.props;
    await resetState();
    this.setLoading(true);
    await changeFilter({
      sortBy, sortDirection, searchText, range,
    });
    this.setLoading(false);
  };

  changeFilterDebounce = async () => {
    if (this.changeFilterTimout) clearTimeout(this.changeFilterTimout);
    this.setLoading(true);
    this.changeFilterTimout = setTimeout(async () => {
      this.changeFilterTimout = null;
      await this.changeFilter();
    }, 500);
  };

  getScrollBarWith = (scrollRef) => {
    const { onScrollbarWidthChange } = this.props;
    setTimeout(() => {
      if (scrollRef) {
        const scrollBarWidth = Math.round(
          scrollRef.offsetWidth - scrollRef.children[0].offsetWidth,
        );
        if (scrollBarWidth === this.state.scrollBarWidth) return;
        this.setState({ scrollBarWidth });
        onScrollbarWidthChange(scrollBarWidth);
      }
    }, 10);
  };

  setLoading = (state) => {
    const { onLoadingStateChange } = this.props;
    this.isLoading = state;
    this.setState({ isListLoading: state });
    onLoadingStateChange(state);
  };

  loadItems = async () => {
    const { fetchItems, items, searchText } = this.props;
    if (!this.isLoading) {
      this.setLoading(true);
      try {
        await fetchItems({ pageSize: 60, skip: items.length, searchText });
      } catch (x) {
        // Error
      } finally {
        this.setLoading(false);
      }
    }
  };

  calcHeight = () => {
    const { maxHeight, height } = this.props;
    const { maxHeight: maxHeightState } = this.state;
    if (this.listContainerRef.current && !maxHeight) {
      const { top, right } = this.listContainerRef.current.getBoundingClientRect();
      this.setState({ listTop: top, listRight: right, lastCalcHeight: height });

      const maxHeightRecalculated = window.innerHeight - (top + 1);
      if (maxHeightState !== maxHeightRecalculated) {
        this.setState({ maxHeight: maxHeightRecalculated });
      }
    }
  };

  renderRow = (index, item) => {
    const {
      renderRow, useTableStyles,
    } = this.props;
    let style = {};
    if (useTableStyles) {
      style = index % 2 === 1 ? { backgroundColor: '#f5f5f5' } : { };
    }
    return renderRow({
      index, item, style,
    });
  };

  renderContents = () => {
    const {
      items,
      hasMore,
      emptyContent,
      notFoundContent,
      searchText,
      additionalLoadingProp,
    } = this.props;
    const { isListLoading, maxHeight } = this.state;
    const shouldRenderNotFoundContent
        = !isListLoading && items.length === 0 && !hasMore && searchText;
    const shouldRenderEmptyContent
        = !isListLoading && items.length === 0 && !hasMore && !searchText;
    const shouldRenderLoadingAnim
    = (isListLoading || this.isLoading) && items.length === 0;

    if (shouldRenderNotFoundContent && notFoundContent) {
      return (
        <div style={{ height: maxHeight }} dataTest={'notFoundContent'} className={cx(styles.extraContent, styles.extraContentNotFound)}>
          {notFoundContent}
        </div>
      );
    }
    if (shouldRenderEmptyContent && emptyContent) {
      return (
        <div style={{ height: maxHeight }} dataTest={'emptyContent'} className={cx(styles.extraContent, styles.extraContentEmpty)}>
          {emptyContent}
        </div>
      );
    }
    if (shouldRenderLoadingAnim) {
      return (
        <div style={{ height: maxHeight }}>
          <TableLoading />
        </div>
      );
    }
    if (additionalLoadingProp) {
      return (
        <div style={{ height: maxHeight }}>
          <TableLoading isScroll />
        </div>
      );
    }
    return null;
  };

  renderFooter = () => {
    const {
      hasMore,
      additionalContent,
      items,
      isMobileSize,
    } = this.props;
    const { isListLoading } = this.state;
    if (additionalContent) return additionalContent;
    if (isListLoading && hasMore && items.length > 0) {
      return (
        <div>
          <TableLoading isScroll/>
        </div>
      );
    }
    if (isMobileSize) {
      return (
        <div className={styles.fabPlaceHolder} />
      );
    }
    return null;
  };

  render() {
    const {
      items,
      useTableStyles,
      hasMore,
      additionalLoadingProp,
    } = this.props;
    const { maxHeight } = this.state;
    return (
      <div ref={this.listContainerRef}>
        {!additionalLoadingProp && (
          <Virtuoso
            style={{ height: items.length === 0 ? 0 : maxHeight }}
            data={items}
            computeItemKey={(index) => index}
            itemContent={(index, item) => (
              <>
                {this.renderRow(index, item)}
              </>
            )}
            atBottomStateChange={(atBottom) => (atBottom && hasMore) && this.loadItems() }
            components={{
              Footer: () => this.renderFooter(),
            }}
            scrollerRef={(ref) => this.getScrollBarWith(ref)}
            totalListHeightChanged={() => this.calcHeight()}
            className={
              cx(
                { [styles.tableStyles]: useTableStyles },
              )
            }
          />
        )}

        {this.renderContents()}
      </div>
    );
  }
}

export default withWindowSize(LazyLoadingList);
