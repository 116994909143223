import io from 'socket.io-client';
import { UPDATE_INVOICE_SOCKET } from 'resources/invoice/invoice.actions';
import { UPDATE_OFFER_SOCKET } from 'resources/offer/offer.actions';
import { UPDATE_GARAGE_SOCKET } from 'resources/garage/garage.actions';
import { UPDATE_SETTINGS_SOCKET } from 'resources/printingSettings/printingSettings.actions';
import {
  UPDATE_CALENDAR,
  DELETE_CALENDAR,
  UPDATE_CALENDAR_ENTRY,
  DELETE_CALENDAR_ENTRY,
} from 'resources/calendar/calendar.actions';
import { UPDATE_SDI_STATUS_SOCKET } from 'resources/sdi/sdi.actions';

const CALENDAR_EVENTS = {
  entries: {
    update: UPDATE_CALENDAR_ENTRY,
    delete: DELETE_CALENDAR_ENTRY,
  },
  calendars: {
    update: UPDATE_CALENDAR,
    delete: DELETE_CALENDAR,
  },
};

export const socketConnect = (store) => {
  const { socketUrl, garage, token } = window.config;
  const socket = io.connect(socketUrl, {
    query: `token=${token}`,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    closeOnBeforeunload: false,
  });

  socket.on('connect', () => socket.emit('subscribe', garage._id));

  socket.on('reconnect', () => socket.emit('subscribe', garage._id));

  socket.on('invoice-generation-start', (payload) => store.dispatch({ type: UPDATE_INVOICE_SOCKET, payload }));
  socket.on('invoice-generation-end', (payload) => store.dispatch({ type: UPDATE_INVOICE_SOCKET, payload }));
  socket.on('offer-generation-start', (payload) => store.dispatch({ type: UPDATE_OFFER_SOCKET, payload }));
  socket.on('offer-generation-end', (payload) => store.dispatch({ type: UPDATE_OFFER_SOCKET, payload }));
  socket.on('updatePrintingSettings', (payload) => store.dispatch({ type: UPDATE_SETTINGS_SOCKET, payload }));
  socket.on('stripeUpdateWebhook', (payload) => store.dispatch({ type: UPDATE_GARAGE_SOCKET, payload }));
  socket.on('added-invoice-event', (payload) => store.dispatch({ type: UPDATE_INVOICE_SOCKET, payload }));
  socket.on('added-offer-event', (payload) => store.dispatch({ type: UPDATE_OFFER_SOCKET, payload }));
  socket.on('print-in-browser', ({ pdfUrl }) => window.open(pdfUrl, '_blank'));
  socket.on('sdi-status-update', (payload) => store.dispatch({ type: UPDATE_SDI_STATUS_SOCKET, payload }));

  socket.on('calendar-changed', (payload) => {
    const { type, eventType, data } = payload;

    store.dispatch({ type: CALENDAR_EVENTS[type][eventType], payload: data });
  });

  return socket;
};

export default socketConnect;
