import { MdInfo as InfoIcon } from 'react-icons/md';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faDolly } from 'fontawesome/pro-solid-svg-icons/faDolly';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './tooltip.styles.pcss';

class Tooltip extends PureComponent {
  static propTypes = {
    variant: PropTypes.string.isRequired,
    isVisable: PropTypes.bool,
    message: PropTypes.any,
    iconPosition: PropTypes.string,
    tooltipPosition: PropTypes.string,
    children: PropTypes.element,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    message: '',
    isVisable: true,
  };

  render() {
    const { variant, isVisable } = this.props;
    return (
      isVisable && (
      <>
        {variant && variant === 'info' && (
        <div className={styles.tooltipContainer}>
          <InfoIcon
            className={cx(styles.tooltipIcon, this.props.iconPosition)}
          />
          <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
            {this.props.message}
          </div>
        </div>
        )}
        {variant && variant === 'details' && (
          <div className={styles.tooltipContainer}>
            <div className={cx(styles.tooltipIcon, this.props.iconPosition)} />
            <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
              {this.props.message}
            </div>
          </div>
        )}
        {variant && variant === 'error' && (
        <div className={cx(styles.tooltipError, styles.tooltipNew, this.props.tooltipPosition)}>
          {this.props.message}
        </div>
        )}
        {variant && variant === 'isUnderInventoryMintrue' && (
        <div className={cx(styles.tooltipContainer)}>
          <FontAwesomeIcon
            onClick={this.props.onClick}
            icon={faDolly}
            className={cx(styles.tooltipIcon, this.props.iconPosition)}
          />
          <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
            {this.props.children}
          </div>
        </div>
        )}
        {variant && variant === 'isUnderInventoryMinequal' && (
        <div className={cx(styles.tooltipContainer)}>
          <FontAwesomeIcon
            icon={faDolly}
            className={cx(styles.tooltipIcon, this.props.iconPosition)}
            onClick={this.props.onClick}
          />
          <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
            {this.props.children}
          </div>
        </div>
        )}
        {variant && variant === 'isUnderInventoryMinempty' && (
        <div className={cx(styles.tooltipContainer)}>
          <FontAwesomeIcon
            icon={faDolly}
            className={cx(styles.tooltipIcon, this.props.iconPosition)}
            onClick={this.props.onClick}
          />
          <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
            {this.props.children}
          </div>
        </div>
        )}
        {variant && variant === 'isUnderInventoryMinemptyNow' && (
        <div className={cx(styles.tooltipContainer)}>
          <FontAwesomeIcon
            icon={faDolly}
            className={cx(styles.tooltipIcon, this.props.iconPosition)}
            onClick={this.props.onClick}
          />
          <div className={cx(styles.tooltip, this.props.tooltipPosition)}>
            {this.props.children}
          </div>
        </div>
        )}
      </>
      )
    );
  }
}

export default Tooltip;
