import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MdKeyboardArrowLeft as ArrowIcon } from 'react-icons/md';
import polyglot from 'services/localization';
import styles from './breadcrumbs.styles.pcss';

class Breadcrumbs extends PureComponent {
  static propTypes = {
    currentClient: PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }).isRequired,
    currentVehicle: PropTypes.shape({
      _id: PropTypes.string,
      manufacturer: PropTypes.string,
      model: PropTypes.string,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
  };

  getTitle = (path) => {
    const { currentClient, currentVehicle } = this.props;

    switch (path) {
      case '/clients/all':
      case '/clients/birthday-list':
        return polyglot.t('header.clients');
      case '/reports':
        return polyglot.t('reports.reports');
      case '/reports/products':
        return polyglot.t('header.products');
      case `/clients/all/${currentClient._id}`:
      case `/clients/birthday-list/${currentClient._id}`:
        return `${currentClient.lastName} ${currentClient.firstName}`;
      case '/vehicles/all':
      case '/vehicles/57a':
      case '/vehicles/tuv':
      case '/vehicles/nextInspection':
      case '/vehicles/tires':
        return polyglot.t('header.vehicles');
      case `/vehicles/all/${currentVehicle._id}`:
      case `/vehicles/57a/${currentVehicle._id}`:
      case `/vehicles/tuv/${currentVehicle._id}`:
      case `/vehicles/nextInspection/${currentVehicle._id}`:
      case `/vehicles/tires/${currentVehicle._id}`:
        return `${currentVehicle.manufacturer} ${currentVehicle.model}`;
      default:
        return polyglot.t('actions.cancel');
    }
  };

  render() {
    const { pathname } = this.props;

    const allPathParts = pathname.split('/');
    const pathParts = allPathParts.slice(1, -1);
    if (pathParts[0] !== 'clients' && pathParts[0] !== 'vehicles' && pathParts[0] !== 'products' && pathParts[0] !== 'reports') {
      return null;
    }
    if (pathParts[0] === 'products' && allPathParts[3] !== 'history') {
      return null;
    }
    if (pathParts[0] === 'reports') {
      if (allPathParts[4] !== 'history') {
        return null;
      }
      delete pathParts[2];
    }

    const paths = pathParts
      .reduce((result, pathPart) => {
        return [...result, `${_.last(result) || ''}/${pathPart}`];
      }, [])
      .slice(pathParts[0] === 'clients' || pathParts[0] === 'vehicles' ? 1 : 0);

    return paths.length > 0 ? (
      <div className={styles.breadcrumbs}>
        {paths.map((path) => (
          <Link key={path} className={styles.link} to={path} dataTest="breadcrumbs">
            <ArrowIcon />
            {this.getTitle(path)}
          </Link>
        ))}
      </div>
    ) : null;
  }
}

export default Breadcrumbs;
