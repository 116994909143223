/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { toUTCDay } from 'helpers/date';
import polyglot from 'services/localization';
import ColoredPrice from 'components/common/coloredPrice';
import moment from 'moment';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import License from 'components/common/invoiceItems/components/license';
import StatusLabel from 'components/invoices/statusLabelModern';
import tableStyles from 'styles/tableModern.pcss';
import RcTooltip from 'rc-tooltip';
import RoleManager from 'components/common/roleManager';

import { getInvoiceIconByStatus } from 'helpers/invoice';
import InteractableDiv from 'components/common/interactableDiv';
import { getClientName } from 'helpers/client';
import PaymentsCircleChart from 'components/invoices/invoice/components/paymentsCircleChart';
import styles from './invoiceCard.styles.pcss';

class InvoiceCard extends Component {
  static propTypes = {
    isVehicleView: PropTypes.bool,
    invoice: PropTypes.shape({
      _id: PropTypes.string,
      draftNumber: PropTypes.number,
      items: PropTypes.array,
      updatedOn: PropTypes.string,
      dueDate: PropTypes.string,
      status: PropTypes.string,
      client: PropTypes.object,
      vehicle: PropTypes.object,
      userId: PropTypes.string,
      draftExecutionDate: PropTypes.number,
      invoiceText: PropTypes.string,
      discount: PropTypes.number,
      total: PropTypes.number,
      taxes: PropTypes.number,
      gross: PropTypes.number,
      taxSums: PropTypes.array,
      payments: PropTypes.array,
      openAmount: PropTypes.number,
      number: PropTypes.string,
      createdOn: PropTypes.string,
      dunningStatus: PropTypes.string,
    }).isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    invoices: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    useHover: PropTypes.bool,
    showInvoiceListCards: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    isSelected: PropTypes.bool,
    dataTest: PropTypes.string,
    hideAdditionalData: PropTypes.bool,
  };

  static defaultProps = {
    isVehicleView: false,
    useHover: true,
    history: null,
    invoices: [],
    showInvoiceListCards: false,
    dataTest: null,
    hideAdditionalData: false,
  };

  state = {
  };

  componentDidMount() {
  }

  onOpenInvoice = (clientId, vehicleId, invoiceName, allTypesId) => {
    const { history } = this.props;
    if (!history) return;
    if (!invoiceName || invoiceName === 'active') {
      history.push(`/offers/${allTypesId || 'new'}`, { clientId, vehicleId });
      return;
    }

    history.push(`/invoices/${invoiceName}/${allTypesId || 'new'}`, { clientId, vehicleId });
  };

  getInvoiceDate = (invoice) => {
    let invoiceDate = '';
    if (invoice.invoiceDate) {
      invoiceDate = toUTCDay(invoice.invoiceDate) === 'Invalid date' ? '' : toUTCDay(invoice.invoiceDate);
    } else if (invoice.draftExecutionDate) {
      invoiceDate = toUTCDay(invoice.draftExecutionDate) === 'Invalid date' ? '' : toUTCDay(invoice.draftExecutionDate);
    } else if (invoice.createdOn) {
      invoiceDate = toUTCDay(invoice.createdOn) === 'Invalid date' ? '' : toUTCDay(invoice.createdOn);
    }
    return invoiceDate;
  };

  createDunningWithTooltip = (invoice) => {
    return (
      <RcTooltip
        placement="top"
        trigger={['hover']}
        destroyTooltipOnHide
        id={`dunningTooltip-${invoice._id}`}
        overlay={(
          <div className={styles.tooltipDunning}>
            {invoice.dunningStatus === 'reminder' && (
            <>
              <div className={styles.dunningTooltipRow}>
                <div className={cx(styles.dunning, styles.dunningReminder)}>
                  {'ZE'}
                </div>
                <div className={styles.dunningTooltipText}>
                  {polyglot.t('invoices.reminderTexts.reminder')}
                </div>
              </div>
              <div className={styles.dunningTooltipRow}>
                {polyglot.t('invoices.reminderTexts.created')}
                {' '}
                {moment(invoice.pdfs?.reminder?.startedUploadOn).from(new Date())}
              </div>
            </>
            )}
            {invoice.dunningStatus === 'demandLetter1' && (
            <>
              <div className={styles.dunningTooltipRow}>
                <div className={cx(styles.dunning, styles.dunningFirst)}>
                  {'1.'}
                </div>
                <div className={styles.dunningTooltipText}>
                  {polyglot.t('invoices.reminderTexts.demand')}
                </div>
              </div>
              <div className={styles.dunningTooltipRow}>
                {polyglot.t('invoices.reminderTexts.created')}
                {' '}
                {moment(invoice.pdfs?.demandLetter1?.startedUploadOn).from(new Date())}
              </div>
            </>
            )}
            {invoice.dunningStatus === 'demandLetter2' && (
            <>
              <div className={styles.dunningTooltipRow}>
                <div className={cx(styles.dunning, styles.dunningSecond)}>
                  {'2.'}
                </div>
                <div className={styles.dunningTooltipText}>
                  {polyglot.t('invoices.reminderTexts.demand')}
                </div>
              </div>
              <div className={styles.dunningTooltipRow}>
                {polyglot.t('invoices.reminderTexts.created')}
                {' '}
                {moment(invoice.pdfs?.demandLetter2?.startedUploadOn).from(new Date())}
              </div>
            </>
            )}
            {invoice.dunningStatus === 'demandLetter3' && (
            <>
              <div className={styles.dunningTooltipRow}>
                <div className={cx(styles.dunning, styles.dunningThird)}>
                  {'3.'}
                </div>
                <div className={styles.dunningTooltipText}>
                  {polyglot.t('invoices.reminderTexts.demand')}
                </div>
              </div>
              <div className={styles.dunningTooltipRow}>
                {polyglot.t('invoices.reminderTexts.created')}
                {' '}
                {moment(invoice.pdfs?.demandLetter3?.startedUploadOn).from(new Date())}
              </div>
            </>
            )}
          </div>
    )}
      >
        <span data-tip data-for={`dunningTooltip-${invoice._id}`} data-type="light" data-effect="solid" data-border data-place="top">
          {invoice.dunningStatus === 'reminder' && (
          <div className={cx(
            { [styles.dunningEven]: this.count % 2 === 0 },
            styles.dunning,
            styles.dunningReminder,
          )}>
            {'ZE'}
          </div>
          )}
          {invoice.dunningStatus === 'demandLetter1' && (
          <div className={cx(
            { [styles.dunningEven]: this.count % 2 === 0 },
            styles.dunning,
            styles.dunningFirst,
          )}>
            {'1.'}
          </div>
          )}
          {invoice.dunningStatus === 'demandLetter2' && (
          <div className={cx(
            { [styles.dunningEven]: this.count % 2 === 0 },
            styles.dunning,
            styles.dunningSecond,
          )}>
            {'2.'}
          </div>
          )}
          {invoice.dunningStatus === 'demandLetter3' && (
          <div className={cx(
            { [styles.dunningEven]: this.count % 2 === 0 },
            styles.dunning,
            styles.dunningThird,
          )}>
            {'3.'}
          </div>
          )}
        </span>
      </RcTooltip>
    );
  };

  renderTableHeader = () => {
    const { isVehicleView } = this.props;
    return (
      <thead className={tableStyles.tableHeader}>
        <tr style={{ height: '40px', fontSize: '14px' }} className={tableStyles.tableHeaderRow}>
          <td className={cx(tableStyles.tableElement, styles.tdStatus)}>
            {polyglot.t('invoices.fields.status')}
          </td>
          <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdNumber,
          )}>
            {polyglot.t('invoices.fields.number')}
          </td>
          <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdDate,
          )}>
            {polyglot.t('invoices.fields.listDate')}
          </td>
          <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdVehicle,
          )}>
            {
              isVehicleView
                ? polyglot.t('invoices.fields.customer')
                : polyglot.t('invoices.fields.vehicle')
            }
          </td>

          <RoleManager forbiddenRoles={['worker']}>
            <td className={cx(
              tableStyles.tableElement,
              tableStyles.tableElementActions,
              styles.tdSum,
              styles.tdSumHeader,
            )}>
              <div>
                {polyglot.t('invoices.fields.gross')}
              </div>
            </td>
          </RoleManager>
        </tr>
      </thead>
    );
  };

  renderTableBody = (invoice) => {
    const { dataTest, isVehicleView } = this.props;

    return (
      <tr
        dataTest={dataTest}
        onClick={() => this.onOpenInvoice(
          invoice.client._id,
          invoice.vehicle._id,
          invoice.status,
          invoice._id,
        )}
        className={cx(tableStyles.tableRow, styles.tableRow)}>
        <td className={cx(
          tableStyles.tableElement,
          tableStyles.tableElementActions,
          styles.tdStatus,
        )}>
          <div className={styles.containerCardElementInline}>
            <StatusLabel
              dueDate={invoice.dueDate}
              gross={invoice.gross}
              status={invoice.status || 'offer'} />
          </div>
          {invoice.dunningStatus !== 'none' && (
          <>
            {this.createDunningWithTooltip(invoice)}
          </>
          )}
        </td>
        <td className={cx(
          tableStyles.tableElement,
          tableStyles.tableElementActions,
          styles.tdNumber,
        )}>
          {`${invoice.number || invoice.draftNumber || polyglot.t('invoices.statuses.preview')}`}
        </td>
        <td className={cx(
          tableStyles.tableElement,
          tableStyles.tableElementActions,
          styles.tdDate,
        )}>
          {this.getInvoiceDate(invoice)}
        </td>
        {
          isVehicleView ? <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdVehicle,
          )}>
            {invoice.client && <>
              {getClientName(invoice.client)}
            </>}
          </td> : <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdVehicle,
          )}>
            {invoice.vehicle._id ? (
              <div className={styles.containerVehicle}>

                <div className={styles.containerVehicleLicense}>
                  <License
                    licenseNumber={invoice.vehicle.license}
                    country={invoice.owner.country}
                  />
                </div>
                <div className={styles.containerVehicleModel}>
                  {`${invoice.vehicle.manufacturer} ${invoice.vehicle.model}`}
                </div>
              </div>
            ) : (
              <div>
                {polyglot.t('vehicles.noVehicle')}
              </div>
            )}
          </td>
        }

        <RoleManager forbiddenRoles={['worker']}>
          <td className={cx(
            tableStyles.tableElement,
            tableStyles.tableElementActions,
            styles.tdSum,
          )}>
            <div className={cx(styles.containerCardElementInline, styles.gross)}>
              <ColoredPrice style={{ marginTop: 'auto' }} priceValue={invoice.gross} />
              <PaymentsCircleChart invoice={invoice}/>
            </div>
          </td>
        </RoleManager>
      </tr>
    );
  };

  renderCard = (invoice) => {
    const {
      useHover, actions, isSelected, hideAdditionalData, dataTest,
    } = this.props;
    const invoiceIcon = getInvoiceIconByStatus(invoice.status, !['open', 'overdue', 'paid', 'paidOut'].includes(invoice.status));
    const invoiceIsDeleted = !!invoice.removedOn;
    return (
      <InteractableDiv
        onClick={() => this.onOpenInvoice(
          invoice.client._id,
          invoice.vehicle._id,
          invoice.status,
          invoice._id,
        )}
        key={invoice._id}
        className={cx(
          this.props.invoice ? styles.containerCardBorder : {},
          { [styles.containerCardBorderSelected]: isSelected },
          { [styles.containerCardDeleted]: invoiceIsDeleted },
          useHover && !invoiceIsDeleted ? styles.containerCardHover : {},
        )}
        dataTest={dataTest}
      >
        <div className={cx(styles.containerCardRow)}>
          <div className={cx(styles.containerCardElement, styles.containerCardElementFullLine)}>
            <div className={styles.containerCardElementDunning}>
              <StatusLabel
                dueDate={invoice.dueDate}
                gross={invoice.gross}
                status={invoice.status || 'offer'} />
              {invoice.dunningStatus !== 'none' && (
              <>
                {this.createDunningWithTooltip(invoice)}
              </>
              )}
              {!hideAdditionalData && (
              <div>
                {`Nr. ${invoice.number || invoice.draftNumber || polyglot.t('invoices.statuses.preview')}`}
              </div>
              )}
            </div>

            <RoleManager forbiddenRoles={['worker']}>
              <div className={cx(styles.containerCardElementInline, styles.gross)}>
                <ColoredPrice priceValue={invoice.gross} />
                <PaymentsCircleChart invoice={invoice} pieChartClassName={styles.pieChart}/>
              </div>
            </RoleManager>

            {actions && (
            <div className={styles.containerCardElementActions}>
              {actions.map((action, i) => {
                return (
                  <InteractableDiv
                    key={i}
                    className={cx(styles.icon, styles.iconAction)}
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); action.action(); }}
                  >
                    {action.icon}
                  </InteractableDiv>
                );
              })}
            </div>
            )}
          </div>

          <div className={cx(
            styles.containerCardElement,
            { [styles.containerCardElementFullLineWithPadding]: hideAdditionalData },
          )}>
            {!hideAdditionalData ? (
              <div className={styles.truncateFormat}>
                {getClientName(invoice.client) || polyglot.t('invoices.fields.noOwner')}
              </div>
            ) : (
              <>
                {`Nr. ${invoice.number || invoice.draftNumber || polyglot.t('invoices.statuses.preview')}`}
              </>
            )}
            <div className={cx(styles.containerCardElementInline)}>
              {this.getInvoiceDate(invoice)}
            </div>
          </div>
          {!hideAdditionalData && (
            <>
              {invoice.vehicle._id ? (
                <div className={styles.containerCardElement}>
                  <div className={styles.truncateFormat}>
                    {invoice.vehicle.manufacturer}
                  </div>
                  <div>
                    <License
                      licenseNumber={invoice.vehicle.license}
                      country={invoice.owner.country}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.containerCardElement}>
                  {polyglot.t('vehicles.noVehicle')}
                </div>
              )}
              <div style={!invoice.status ? { marginBottom: '-8px' } : {}} className={cx(styles.containerImageInvoice, { [styles.containerImageInvoiceSelected]: isSelected })}>
                <div style={{ marginLeft: '20px' }}>
                  {invoiceIcon}
                </div>
              </div>
            </>
          )}

        </div>
      </InteractableDiv>
    );
  };

  render() {
    const {
      isMobileSize,
      invoices,
      invoice,
      showInvoiceListCards,
    } = this.props;
    return (
      <>
        {(isMobileSize || showInvoiceListCards) ? (
          <>
            {invoice ? (
              <>
                {this.renderCard(invoice)}
              </>
            ) : (
              <>
                {invoices.map((invoiceEntry) => (
                  <>
                    {this.renderCard(invoiceEntry)}
                    <div className={styles.seperator} />
                  </>
                ))}
              </>
            )}
          </>
        ) : (
          <>
            {this.renderTableHeader()}
            <table className={tableStyles.table}>
              <tbody className={tableStyles.tableBody}>
                {invoices.map((invoiceToRender) => (
                  <>
                    {this.renderTableBody(invoiceToRender)}
                  </>
                ))}
              </tbody>
            </table>
          </>
        )}
      </>
    );
  }
}

export default withDeviceInfo(InvoiceCard);
