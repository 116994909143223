/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import ReactCrop from 'react-image-crop';
import { getCroppedImg, resizeImage } from 'helpers/canvasUtils';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faRedo } from 'fontawesome/pro-solid-svg-icons';
import Loading from 'components/common/loading/components/tableLoading/tableLoading';
import DeviceService from 'services/device.service';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import styles from './imageEditor.styles.pcss';
import ButtonModern from '../buttonModern';

export default class ImageEditor extends Component {
  static propTypes = {
    style: PropTypes.shape({}),
    className: PropTypes.string,
    editorClass: PropTypes.string,
    imageStyle: PropTypes.shape({}),
    file: PropTypes.shape({}),
    onImageChanged: PropTypes.func,
    onProcessingChanged: PropTypes.func, // Called when image starts/stops being processed
    infoText: PropTypes.string,
    zoomEnabled: PropTypes.bool,
    editEnabled: PropTypes.bool,
    rotateEnabled: PropTypes.bool,
    maxZoom: PropTypes.number,
    minZoom: PropTypes.number,
    src: PropTypes.string,
    autoHeight: PropTypes.bool,
    clipToEdge: PropTypes.bool,
    hasZoomButtons: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    className: null,
    editorClass: null,
    imageStyle: {},
    file: null,
    onImageChanged: () => {},
    onProcessingChanged: () => {},
    infoText: '',
    rotateEnabled: false,
    zoomEnabled: false,
    editEnabled: false,
    maxZoom: 5,
    minZoom: null,
    src: '',
    autoHeight: false,
    clipToEdge: true,
    hasZoomButtons: false,
  };

  constructor(props) {
    super(props);

    this.imageEditorRef = React.createRef();
    this.imageRef = React.createRef();

    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      },
      imageHeight: 200,
      imageWidth: 200,
      imageOffsetTop: 0,
      imageOffsetLeft: 0,
      editorHeight: 200,
      zoomAndPan: {
        top: 0,
        left: 0,
        zoom: 1,
      },
      touchedPos: {},
      isDragging: false,
    };

    this.loadImage();
  }

  componentDidMount() {
    new ResizeObserver((event) => {
      const editor = event[0].target;
      this.setState({
        editorHeight: editor.clientHeight,
      });
    }).observe(this.imageEditorRef.current);
    this.imageEditorRef.current.addEventListener('wheel', this.onEditorScroll, {
      passive: false,
    });
    window.addEventListener('resize', _.debounce(this.resize, 200));
    window.addEventListener('mouseup', () => this.setState({ isDragging: false }));
    window.addEventListener('mousemove', this.onMouseMoveHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', _.debounce(this.resize, 200));
    window.removeEventListener('mouseup', () => this.setState({ isDragging: false }));
    window.removeEventListener('mousemove', this.onMouseMoveHandler);
    this.imageEditorRef.current.removeEventListener('wheel', this.onEditorScroll, {
      passive: false,
    });
  }

  resize = () => {
    const img = this.imageRef.current;
    if (img) {
      const boundingRect = img.getBoundingClientRect();
      this.setState({
        imageOffsetTop: boundingRect.y,
        imageOffsetLeft: boundingRect.x,
      });
    }
  };

  loadImage = async () => {
    const { file, src } = this.props;
    let imageDataUrl = src;
    if (file) {
      imageDataUrl = await this.readFile(file);
    }
    const img = await resizeImage(imageDataUrl, 2000, 2000);
    imageDataUrl = img.data;
    this.setState({ src: imageDataUrl });
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onCropComplete = (crop, cropPercent) => {
    if (cropPercent.width === 0 || cropPercent.height === 0) return;
    this.makeClientCrop(cropPercent);
  };

  onCropChange = async (crop, percentCrop) => {
    if (percentCrop.width === 0 || percentCrop.height === 0) return;
    this.setState({ crop: percentCrop });
  };

  onImageLoaded = (image) => {
    const { crop } = this.state;

    new ResizeObserver((event) => {
      const img = event[0].target;
      const boundingRect = img.getBoundingClientRect();
      this.setState({
        imageHeight: img.height,
        imageWidth: img.width,
        imageOffsetTop: boundingRect.y,
        imageOffsetLeft: boundingRect.x,
      });
    }).observe(image);

    const boundingRect = image.getBoundingClientRect();
    this.setState({
      imageHeight: image.height,
      imageWidth: image.width,
      imageOffsetTop: boundingRect.y,
      imageOffsetLeft: boundingRect.x,
    });
    this.onCropComplete(crop, crop);
    return false;
  };

  onEditorScroll = (e) => {
    const { zoomEnabled } = this.props;
    if (!zoomEnabled) return;
    e.preventDefault();
    const { zoomAndPan, imageOffsetTop, imageOffsetLeft } = this.state;

    const { deltaY } = e;
    let {
      clientX,
      clientY,
    } = e;

    const { zoom } = zoomAndPan;
    const zoomChange = deltaY > 0 ? -0.1 : 0.1;

    clientX -= imageOffsetLeft;
    clientY -= imageOffsetTop;

    const nextZoom = zoomChange + zoom;
    this.zoomTo(nextZoom, clientX, clientY);
    /* const ratio = 1 - nextZoom / zoom;

    left += (clientX - left) * ratio;
    top += (clientY - top) * ratio;

    const clippedVals = this.getClippedCoords(left, top, nextZoom);
    left = clippedVals.left;
    top = clippedVals.top;

    this.setState({ zoomAndPan: { left, top, zoom: nextZoom } }); */
  };

  zoomTo = (nextZoom, leftVal = 0, topVal = 0) => {
    const {
      minZoom, maxZoom, clipToEdge, zoomEnabled,
    } = this.props;
    const { zoomAndPan } = this.state;
    const { zoom } = zoomAndPan;

    if (!zoomEnabled) return;

    let currMinZoom = minZoom;
    if (!minZoom) {
      currMinZoom = clipToEdge ? 1 : 0.5;
    }
    const zoomChange = nextZoom - zoom;
    if ((zoom <= currMinZoom && zoomChange < 0) || (zoom >= maxZoom && zoomChange > 0)) {
      // zoomChange = 0;
      return;
    }

    let { left, top } = zoomAndPan;
    const ratio = 1 - nextZoom / zoom;

    left += (leftVal - left) * ratio;
    top += (topVal - top) * ratio;

    const clippedVals = this.getClippedCoords(left, top, nextZoom);
    left = clippedVals.left;
    top = clippedVals.top;

    this.setState({ zoomAndPan: { left, top, zoom: nextZoom } });
  };

  onMouseDownHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const touchedPos = {
      x: e.clientX,
      y: e.clientY,
    };

    this.setState({ touchedPos, isDragging: true });
  };

  onMouseMoveHandler = (e) => {
    const {
      touchedPos, isDragging, zoomAndPan,
    } = this.state;
    if (!isDragging) return;
    e.preventDefault();
    e.stopPropagation();
    let { top, left } = zoomAndPan;
    const dx = e.clientX - touchedPos.x;
    const dy = e.clientY - touchedPos.y;

    left += dx;
    top += dy;

    const clipCoords = this.getClippedCoords(left, top);
    left = clipCoords.left;
    top = clipCoords.top;

    this.setState({
      zoomAndPan: { ...zoomAndPan, top, left }, touchedPos: { x: e.clientX, y: e.clientY },
    });
  };

  getClippedCoords = (leftVal, topVal, zoomVal = null) => {
    const { clipToEdge } = this.props;
    const {
      zoomAndPan, imageWidth, imageHeight, editorHeight,
    } = this.state;

    let left = leftVal;
    let top = topVal;
    const zoom = zoomVal || zoomAndPan.zoom;

    if (!clipToEdge) {
      return { left, top };
    }

    const minLeft = -(imageWidth * (zoom - 1));
    if (left < minLeft) left = minLeft;
    if (left > 0) left = 0;

    const maxTop = editorHeight - (imageHeight * zoom);
    if (top < 0 && maxTop >= 0) top = 0;
    else if (top > 0 && maxTop <= 0) top = 0;
    if (maxTop < 0 && top < maxTop) top = maxTop;
    else if (maxTop > 0 && top > maxTop) top = maxTop;
    return { left, top };
  };

  async makeClientCrop(cropPercent) {
    const { onImageChanged, onProcessingChanged } = this.props;
    const { src } = this.state;
    if (src && cropPercent.width && cropPercent.height) {
      try {
        onProcessingChanged(true);
        const croppedImage = await getCroppedImg(
          src,
          cropPercent,
        );
        onImageChanged(croppedImage);
      } catch (e) {
        console.error(e);
      } finally {
        onProcessingChanged(false);
      }
    }
  }

  rotate = () => {
    const { src, crop } = this.state;
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.height;
      canvas.height = img.width;
      canvas.style.position = 'absolute';
      const ctx = canvas.getContext('2d');
      ctx.translate(img.height, img.width / img.height);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(img, 0, 0);
      const newCrop = {
        ...crop,
        width: crop.height,
        height: crop.width,
        y: crop.x,
        x: 100 - crop.height - crop.y,
      };
      this.setState({ src: canvas.toDataURL(), crop: newCrop });
      canvas.remove();
    };
  };

  renderImageContent = () => {
    const {
      imageStyle,
      editEnabled,
      hasZoomButtons,
    } = this.props;

    const {
      src,
      crop,
      imageHeight,
      imageWidth,
      zoomAndPan,
    } = this.state;

    const { zoom, top, left } = zoomAndPan;
    const isMobileDevice = DeviceService.isMobile
    || (DeviceService.isTouchDevice && DeviceService.OS === DeviceService.OS_LIST.MAC_OS);

    if (src) {
      return (
        (isMobileDevice && !editEnabled) ? (
          <TransformWrapper>
            <TransformComponent>
              <img
                alt="img"
                style={imageStyle}
                className={styles.imageEditorImage}
                src={src}
                onLoad={(event) => this.onImageLoaded(event.target)}
                ref={this.imageRef}
              />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <>
            <div className={styles.imageEditorContainer} style={{ transform: `translate(${left}px, ${top}px) scale(${zoom})` }}>
              {editEnabled ? (
                <ReactCrop
                  src={src}
                  crop={crop}
                  imageStyle={imageStyle}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  imageRef={this.imageRef}
                />
              ) : (
                <img
                  alt="img"
                  style={imageStyle}
                  className={styles.imageEditorImage}
                  src={src}
                  onLoad={(event) => this.onImageLoaded(event.target)}
                  ref={this.imageRef}
                />
              )}
            </div>
            {hasZoomButtons && (
              <div className={styles.imageEditorZoomButtons}>
                <ButtonModern styleType="select" onClick={() => this.zoomTo(zoomAndPan.zoom + 0.1, imageWidth / 2, imageHeight / 2)}>
                  +
                </ButtonModern>
                <ButtonModern styleType="select" onClick={() => this.zoomTo(zoomAndPan.zoom - 0.1, imageWidth / 2, imageHeight / 2)}>
                  -
                </ButtonModern>
              </div>
            )}
          </>
        )
      );
    }
    return (
      <Loading withoutBorder />
    );
  };

  render() {
    const {
      style,
      className,
      editorClass,
      infoText,
      rotateEnabled,
      autoHeight,
    } = this.props;

    const {
      imageHeight,
    } = this.state;

    const imageEditorStyle = {};
    if (autoHeight) imageEditorStyle.height = imageHeight;

    return (
      <div
        className={cx(styles.container, className)}
        style={style}
      >
        {(rotateEnabled || infoText) && (
          <div className={styles.imageEditorHeader}>
            {rotateEnabled && (
              <ButtonModern
                className={styles.imageEditorRotate}
                styleType="select"
                onClick={() => this.rotate()}
              >
                <FontAwesomeIcon icon={faRedo} />
              </ButtonModern>
            )}
            {infoText && <div>
              {infoText}
            </div>}
          </div>
        )}
        <div
          className={cx(styles.imageEditor, editorClass)}
          style={imageEditorStyle}
          onMouseDown={this.onMouseDownHandler}
          ref={this.imageEditorRef}
        >
          {this.renderImageContent()}
        </div>
      </div>
    );
  }
}
