import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import cx from 'classnames';
import { toUTCDay } from 'helpers/date';
import polyglot from 'services/localization';
import { FaCar } from 'react-icons/fa';
import EbvIntegration from 'components/common/ebvIntegration';
import { BiCalendar } from 'react-icons/bi';
import {
  faCalendarAlt,
  faCheck,
  faDolly,
  faEngine,
  faGears,
  faHistory,
  faInfoCircle,
  faInputNumeric,
  faPalette,
  faPen,
  faPlus,
  faRoad,
  faStarOfLife,
  faTire,
  faUser,
  faWeightHanging,
  faXmark,
} from 'fontawesome/pro-solid-svg-icons';
import EditMileages from 'components/common/editMileages';
import _ from 'lodash';
import { getIconFromVehicleClass, getVehicleName } from 'helpers/vehicle';
import { getMatchingMileageString, getSortedMileages } from 'shared-library/src/services/vehicleService';
import VehicleEditModal from 'components/common/vehicleEditModal';
import ButtonModern from 'components/common/buttonModern';
import License from 'components/common/invoiceItems/components/license/license';
import { KWtoHP } from 'helpers/convertUnit';
import CustomStatusLabel from 'components/common/customStatusLabel';
import moment from 'moment';
import toastService from 'helpers/toastService';
import parseError from 'helpers/parseError';
import InteractableDiv from 'components/common/interactableDiv';
import { getClientName } from 'helpers/client';
import { Link } from 'react-router-dom';
import AddonManager from 'components/common/addonManager';
import TireModalContainer from 'components/vehicles/tireModalContainer';
import { getTireIcon } from 'helpers/tire';
import { checkAreFeaturesEnabled } from 'helpers/featuresAndAddons';
import MileageCell from 'components/common/mileageCell';
import { getCarClassStringShort } from 'shared-library/src/definitions/carClasses';
import styles from './vehicleCard.styles.pcss';
import TireCard from '../tireCard/tireCard';

export default class VehicleCard extends Component {
  static propTypes = {
    vehicle: PropTypes.shape({
      client: PropTypes.object,
      license: PropTypes.string,
      dateOfFirstRegistration: PropTypes.string,
      dateOfLastRegistration: PropTypes.string,
      dateOfNextInspection: PropTypes.string,
      acceptanceDate: PropTypes.string,
      nationalCode: PropTypes.string,
      identificationNumber: PropTypes.string,
      manufacturer: PropTypes.string,
      model: PropTypes.string,
      carClass: PropTypes.string,
      colorCode: PropTypes.string,
      ownWeight: PropTypes.number,
      maxWeight: PropTypes.number,
      HSNCode: PropTypes.string,
      TSNCode: PropTypes.string,
      typeOfTransmission: PropTypes.oneOf(['', 'manual', 'automatic']),
      engineCode: PropTypes.string,
      enginePower: PropTypes.number,
      engineDisplacement: PropTypes.number,
      engineCategory: PropTypes.string,
      customField: PropTypes.string,
      baseNumberCh: PropTypes.string,
      documentNumberCh: PropTypes.string,
      additionalInfo: PropTypes.string,
      vehicleVersion: PropTypes.string,
      mileage: PropTypes.array,
      _id: PropTypes.string,
      removedOn: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
      path: PropTypes.string,
      url: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
      location: PropTypes.object,
    }),
    showVehicleDetails: PropTypes.bool,
    showVehicleListCards: PropTypes.bool,
    updateMileageAction: PropTypes.func.isRequired,
    updateVehicle: PropTypes.func.isRequired,
    garage: PropTypes.shape({
      vehicleCustomFieldName: PropTypes.string,
      country: PropTypes.string,
      subscriptionData: PropTypes.shape({
        addons: PropTypes.shape({
          tires: PropTypes.bool,
        }),
      }),
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({})),
    isSelected: PropTypes.bool,
    isMobileSize: PropTypes.bool.isRequired,
    dataTest: PropTypes.string,
    hideAdditionalData: PropTypes.bool,
    useHoverStyles: PropTypes.bool,
    showRemoveIcon: PropTypes.bool,
    onRemoveClicked: PropTypes.func,
    ignoreRemovedStyles: PropTypes.bool,
    onClick: PropTypes.func,
    onUpdateSuccess: PropTypes.func,
    calculateDateOfNextInspectionForCountry: PropTypes.func,
    showVehicleRowCard: PropTypes.bool,
    fetchTires: PropTypes.func.isRequired,
    showVehicleTires: PropTypes.bool,
    tires: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalAmountTires: PropTypes.number.isRequired,
    resetTiresState: PropTypes.func.isRequired,
    showNameLinks: PropTypes.bool,
    useHoverRemoveStyles: PropTypes.bool,
    hideEditContent: PropTypes.bool,
    mileageReferenceDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    showVehicleDetails: false,
    showVehicleListCards: false,
    showVehicleRowCard: false,
    hideAdditionalData: false,
    actions: null,
    isSelected: false,
    dataTest: null,
    history: null,
    match: null,
    useHoverStyles: true,
    showRemoveIcon: false,
    onRemoveClicked: () => {},
    ignoreRemovedStyles: false,
    onClick: () => {},
    onUpdateSuccess: () => {},
    showVehicleTires: false,
    showNameLinks: false,
    useHoverRemoveStyles: false,
    hideEditContent: false,
    mileageReferenceDate: new Date(),
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    VehicleIcon: FaCar,
    isRemoveIconHovered: false,
    isTireModalOpen: false,
    isFetchTiresLoading: false,
  };

  componentDidMount() {
    const {
      vehicle,
      resetTiresState,
      showVehicleRowCard,
      showVehicleTires,
    } = this.props;
    if (vehicle) {
      if (this.props.vehicle.carClass) {
        this.getVehicleClass();
      }
      if (showVehicleRowCard || showVehicleTires) {
        resetTiresState();
        this.fetchTires();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      vehicle,
      showVehicleRowCard,
      showVehicleTires,
      resetTiresState,
    } = this.props;
    const { isFetchTiresLoading } = this.state;
    if (vehicle) {
      const didVehicleChangeAndShouldFetchTires
      = prevProps.vehicle._id !== vehicle._id
        && (showVehicleRowCard || showVehicleTires)
        && !isFetchTiresLoading;

      if (prevProps.vehicle.carClass !== vehicle.carClass) {
        this.getVehicleClass();
      }
      if (didVehicleChangeAndShouldFetchTires) {
        resetTiresState();
        this.fetchTires();
      }
    }
  }

  fetchTires = async () => {
    const { vehicle, fetchTires, garage } = this.props;

    const isTiresEnabled = checkAreFeaturesEnabled(['tires'], garage.subscriptionData);
    if (!isTiresEnabled) return;
    try {
      this.setState({ isFetchTiresLoading: true });
      await fetchTires({ vehicleId: vehicle._id });
      this.setState({ isFetchTiresLoading: false });
    } catch (err) {
      const msg = parseError(err);
      toastService.showError(msg || polyglot.t('misc.unknownError'));
    }
  };

  getVehicleClass() {
    const { vehicle } = this.props;
    if (vehicle.carClass === undefined) return;
    if (vehicle.carClass === null) {
      return;
    }
    const vehicleIconData = getIconFromVehicleClass(vehicle.carClass);
    if (vehicleIconData) {
      this.setState({ VehicleIcon: vehicleIconData.icon });
    }
  }

  formatNumberString = (value) => {
    if (value) {
      return value.toLocaleString().replace(/,|\./g, ' ');
    }
    return 0;
  };

  onMileageChanged = async (mileage) => {
    const { vehicle, updateMileageAction, onUpdateSuccess } = this.props;
    try {
      const { payload: updatedVehicle } = await updateMileageAction(vehicle._id, mileage);
      onUpdateSuccess(updatedVehicle);
    } catch (err) {
      const msg = parseError(err);
      toastService.showError(msg || polyglot.t('misc.unknownError'));
    }
  };

  getNextInspectionStatus = (vehicle) => {
    let status = 'success';
    if (vehicle.client.country === 'AT') {
      if (moment.utc().diff(vehicle.dateOfNextInspection, 'month') > -4) {
        status = 'warning';
      }
    }
    if (['DE', 'IT'].includes(vehicle.client.country)) {
      const startOfActMonth = moment(new Date()).startOf('month').utc();

      const differenceOfDateOfNextInspection
        = startOfActMonth.diff(moment(vehicle.dateOfNextInspection).startOf('month').utc(), 'month');
      if (differenceOfDateOfNextInspection > -2) {
        status = 'warning';
      }
      if (differenceOfDateOfNextInspection > 0) {
        status = 'error';
      }
    }
    return status;
  };

  updateVehicle(data) {
    const { vehicle, onUpdateSuccess } = this.props;
    const newVehicle = { ...vehicle, ...data };
    this.props.updateVehicle(vehicle._id, newVehicle)
      .then(({ payload: updatedVehicle }) => {
        toastService.showSuccess(polyglot.t('editVehicle.notifications.updated'));
        onUpdateSuccess(updatedVehicle);
      })
      .catch((err) => {
        const msg = parseError(err);
        toastService.showError(msg || polyglot.t('misc.unknownError'));
      });
  }

  updateNextInspectionDateForCountry(country) {
    const { vehicle, onUpdateSuccess, calculateDateOfNextInspectionForCountry } = this.props;
    calculateDateOfNextInspectionForCountry(country, vehicle._id)
      .then(({ payload: updatedVehicle }) => {
        toastService.showSuccess(polyglot.t('editVehicle.notifications.updated'));
        onUpdateSuccess(updatedVehicle);
      })
      .catch((err) => {
        const msg = parseError(err);
        toastService.showError(msg || polyglot.t('misc.unknownError'));
      });
  }

  renderCardNameWithLink = () => {
    const {
      vehicle,
      showNameLinks,
    } = this.props;
    if (vehicle.removedOn || !showNameLinks) {
      return (
        <div className={cx(styles.truncateFormat, styles.rowCardTitle)}>
          {getVehicleName(vehicle)}
        </div>
      );
    }
    return (
      <Link
        to={`/vehicles/all/${vehicle._id}`}
        className={styles.link}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={cx(
            styles.containerCardElementName,
            styles.truncateFormat,
          )}
        >
          <div className={cx(styles.truncateFormat, styles.rowCardTitle)}>
            {getVehicleName(vehicle)}
          </div>
        </div>
      </Link>
    );
  };

  renderIconAndActions = (showIcon = true, isVehicleDetails = false, isVehicleRow = false) => {
    const {
      vehicle,
      actions,
      isSelected,
      showRemoveIcon,
      onRemoveClicked,
      ignoreRemovedStyles,
      useHoverRemoveStyles,
    } = this.props;
    const { VehicleIcon, isRemoveIconHovered } = this.state;
    const showWhiteIcon
    = (isSelected || vehicle.removedOn || isRemoveIconHovered || useHoverRemoveStyles)
    && !ignoreRemovedStyles;
    return (
      <div className={styles.containerCardIconAndActions}>
        {(actions || showRemoveIcon) && (
        <div className={cx(
          styles.containerCardElementActions,
          { [styles.containerCardElementActionsDetails]: isVehicleDetails },
          { [styles.containerCardElementActionsNoPadding]: isVehicleRow },
        )}>
          {actions && actions.map((action, i) => {
            return (
              <InteractableDiv
                key={i}
                className={cx(styles.icon, styles.iconAction)}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); action.action(); }}
              >
                {action.icon}
              </InteractableDiv>
            );
          })}
          {showRemoveIcon && (
            <InteractableDiv
              className={cx(styles.icon, styles.iconAction, styles.iconActionRemove)}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); onRemoveClicked(); }}
              onMouseEnter={(e) => { this.setState({ isRemoveIconHovered: true }); }}
              onMouseLeave={(e) => { this.setState({ isRemoveIconHovered: false }); }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </InteractableDiv>
          )}
        </div>
        )}

        {showIcon && (
          <div className={cx(
            styles.containerImageVehicleList,
            { [styles.containerImageVehicleListSelected]: showWhiteIcon },
          )}
          >
            <div className={styles.containerImageVehicleIcon}>
              <VehicleIcon />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderVehicleListCard = () => {
    const {
      vehicle,
      isSelected,
      dataTest,
      hideAdditionalData,
      useHoverStyles,
      ignoreRemovedStyles,
      onClick,
    } = this.props;
    const { isRemoveIconHovered } = this.state;
    let mileage;
    if (vehicle.mileage[0]) {
      mileage = _.first(getSortedMileages(vehicle.mileage));
    }
    return (
      <InteractableDiv
        className={cx(
          styles.containerCard,
          { [styles.containerCardSelected]: isSelected },
          {
            [styles.containerCardHover]:
            useHoverStyles && !vehicle.removedOn && !isRemoveIconHovered,
          },
          { [styles.containerCardDeleted]: vehicle.removedOn && !ignoreRemovedStyles },
          { [styles.containerCardOnRemove]: isRemoveIconHovered },
        )}
        dataTest={dataTest}
        onClick={onClick}
      >
        <div style={{ width: '100%' }} className={styles.containerCardData}>
          {!hideAdditionalData && (
          <div className={cx(
            styles.containerCardElementList,
            styles.containerCardElementName,
          )}>
            <div className={cx(styles.truncateFormat, styles.truncateFormatCardName)}>
              {getClientName(vehicle.client)}
            </div>
          </div>
          )}
          <div>
            {vehicle.license && (
            <div style={{ paddingRight: '5%' }} className={cx(styles.containerCardElementList)}>
              <License
                licenseNumber={vehicle.license}
                country={vehicle.client.country}
              />
            </div>
            )}

            <div className={cx(styles.containerCardElementList, styles.containerCardElementInline)}>
              <div style={{ whiteSpace: 'nowrap' }} className={styles.truncateFormat}>
                {vehicle.manufacturer || ' '}
              </div>
            </div>
            <div className={cx(styles.containerCardElementList, styles.containerCardElementInline)}>
              <div style={{ whiteSpace: 'nowrap' }} className={styles.truncateFormat}>
                {vehicle.model || ' '}
              </div>
            </div>
            <div className={cx(styles.containerCardElementList)}>
              {vehicle.dateOfFirstRegistration && (
                <div className={cx(styles.containerCardElementInline)}>
                  <FontAwesomeIcon icon={faCalendarAlt} fixedWidth className={styles.icon} />
                  {`${toUTCDay(vehicle.dateOfFirstRegistration) === 'Invalid date' ? '' : toUTCDay(vehicle.dateOfFirstRegistration)}`}
                </div>
              )}
              {mileage && (
                <div className={cx(styles.containerCardElementRight)}>
                  <FontAwesomeIcon fixedWidth icon={faRoad} className={styles.icon} />
                  <MileageCell
                    value={mileage.value}
                    operatingHours={mileage.operatingHours}
                  />
                </div>
              )}
            </div>
            {vehicle.additionalInfo && (
              <div className={cx(styles.containerCardElementList)}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faInfoCircle}
                  className={styles.icon}
                />
                <div className={styles.containerCardElementEllipsis}>
                  {vehicle.additionalInfo}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.renderIconAndActions()}
      </InteractableDiv>
    );
  };

  nextInspectionForCountry(country) {
    if (country === 'AT') {
      this.updateVehicle({ wasAlreadyInspected: true });
    }
    if (['DE', 'IT'].includes(country)) {
      this.updateNextInspectionDateForCountry(country);
    }
  }

  renderVehicleDetailsCard = () => {
    const {
      vehicle,
      isMobileSize,
      history,
      garage,
      dataTest,
      onUpdateSuccess,
      showVehicleTires,
      showNameLinks,
      hideEditContent,
    } = this.props;
    const { VehicleIcon } = this.state;
    let typeOfTransmission = '';
    if (vehicle.typeOfTransmission === 'automatic') {
      typeOfTransmission = polyglot.t('editVehicle.form.typeOfTransmission.automatic');
    } else if (vehicle.typeOfTransmission === 'manual') {
      typeOfTransmission = polyglot.t('editVehicle.form.typeOfTransmission.manual');
    }
    const containerWidth = this.containerRef?.current?.getBoundingClientRect().width;
    let mileage;
    if (vehicle.mileage[0]) {
      mileage = _.first(getSortedMileages(vehicle.mileage));
    }

    const showNextInspectionButton = (['DE', 'IT'].includes(vehicle.client.country) || (vehicle.client.country === 'AT' && this.getNextInspectionStatus(vehicle) !== 'success')) && !hideEditContent;

    return (
      <>
        <div
          dataTest={dataTest}
          className={styles.vehicleCardForm}
          ref={this.containerRef}
        >
          <div
            className={cx(styles.containerCardDetails, styles.containerCardOneColumn)}
          >
            <div className={styles.vehicleCardFormRowBlock}>
              <div className={styles.headerTitle}>
                <div
                  className={styles.containerImageVehicleDetails}
                >
                  <VehicleIcon className={styles.headerIcon}> </VehicleIcon>
                </div>

                <div className={cx(styles.headerElement)}>
                  <>
                    {showNameLinks ? (
                      <Link
                        to={`/vehicles/all/${vehicle._id}`}
                        className={styles.link}
                      >
                        <div style={isMobileSize ? { whiteSpace: 'pre-wrap' } : { whiteSpace: 'nowrap' }} className={cx(styles.headerName)}>
                          <div
                            style={{ maxWidth: containerWidth - 210 }}
                            className={styles.truncateFormat}
                          >
                            {`${vehicle.manufacturer}\n${vehicle.model}\t`}
                            <div style={!isMobileSize ? { marginLeft: '10px' } : {}} className={cx(styles.headerText, styles.containerCardElementInline)}>
                              {vehicle.vehicleVersion}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div style={isMobileSize ? { whiteSpace: 'pre-wrap' } : { whiteSpace: 'nowrap' }} className={cx(styles.headerName, styles.headerNameWihoutLink)}>
                        <div
                          style={{ maxWidth: containerWidth - 210 }}
                          className={styles.truncateFormat}
                        >
                          {`${vehicle.manufacturer}\n${vehicle.model}\t`}
                          <div style={!isMobileSize ? { marginLeft: '10px' } : {}} className={cx(styles.headerText, styles.containerCardElementInline)}>
                            {vehicle.vehicleVersion}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={cx(styles.containerCardElement, styles.headerLicense)}>
                      <License
                        licenseNumber={vehicle.license}
                        country={vehicle.client.country}
                      />
                    </div>
                    <InteractableDiv
                      className={cx(styles.headerClient, styles.link)}
                      onClick={() => history.push(`/clients/all/${vehicle.client.clientId || vehicle.client._id}`)}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faUser}
                        className={cx(styles.headerClientIcon)}
                      />
                      <div
                        style={{ maxWidth: containerWidth - 200 }}
                        className={styles.truncateFormat}
                      >
                        {getClientName(vehicle.client)}
                      </div>
                    </InteractableDiv>
                  </>
                </div>

                <div className={styles.headerRight}>
                  {this.renderIconAndActions(false, true)}
                  <VehicleEditModal
                    onSuccess={(updatedVehicle) => onUpdateSuccess(updatedVehicle)}
                    client={vehicle.client}
                    vehicle={vehicle}
                  >
                    {!hideEditContent && (
                    <div className={styles.containerAction}>
                      <ButtonModern
                        styleType="select"
                        size="small"
                        title={polyglot.t('actions.edit')}
                      >
                        {!isMobileSize && polyglot.t('actions.edit')}
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faPen}
                          className={styles.containerActionIcon}
                        />
                      </ButtonModern>
                    </div>
                    )}
                  </VehicleEditModal>
                  <div style={isMobileSize ? { flexFlow: 'column-reverse' } : {}} className={styles.containerAction}>
                    {vehicle.client.country === 'AT' && (
                      <EbvIntegration className={styles.ebv} vehicleId={vehicle._id} type="Vehicle" isShownAsModern />
                    )}
                    <ButtonModern
                      styleType="select"
                      size="small"
                      title={polyglot.t('actions.history')}
                      onClick={() => history.push(`/vehicles/all/${vehicle._id}/products`)}
                    >
                      {!isMobileSize && polyglot.t('actions.history')}
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faHistory}
                        className={styles.containerActionIcon}
                      />
                    </ButtonModern>
                  </div>
                </div>
              </div>

              <div className={styles.headerSeperator} />

              <div className={cx(styles.containerCardDataDetails, styles.containerCardDualData)}>
                <div className={styles.containerMileage}>
                  <div className={cx(styles.containerMileageText)}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faRoad}
                      className={styles.icon}
                    />
                    {polyglot.t('clientVehicles.mileage')}
                  </div>
                  <div className={styles.containerMileageContent}>
                    {hideEditContent ? (
                      <div className={styles.containerMileageValue}>
                        {mileage ? (
                          <MileageCell
                            operatingHours={mileage.operatingHours}
                            value={mileage.value}
                          />
                        ) : (
                          `-- ${polyglot.t('units.km')}`
                        )}
                      </div>
                    ) : (
                      <div className={styles.containerMileageButton}>
                        <EditMileages
                          hideHeader
                          vehicle={vehicle}
                          onSave={this.onMileageChanged}
                          onRemove={this.onMileageChanged}
                          size="small"
                        />
                      </div>
                    )}
                    <div className={styles.containerMileageUpdatedOn}>
                      {mileage?.date && `${polyglot.t('common.lastUpdatedOn')} ${moment(mileage?.date).format('DD.MM.YYYY')}`}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 0 }} className={styles.seperator} />
                {vehicle.client.country === 'AT' && (vehicle.nationalCode || !isMobileSize) && (
                  <>
                    <div
                      className={cx(styles.containerCardElement, styles.containerCardElementInline)}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faInputNumeric}
                        className={styles.icon}
                      />
                      {polyglot.t('template.NationalCode')}
                    </div>
                    <div
                      className={cx(styles.containerCardElement, styles.containerCardElementRight)}
                    >
                      <div>
                        {vehicle.nationalCode}
                      </div>
                    </div>
                  </>
                )}
                {vehicle.client.country === 'DE' && ((vehicle.HSNCode || vehicle.TSNCode) || !isMobileSize) && (
                <>
                  <div className={cx(
                    styles.containerCardElement,
                    styles.containerCardElementInline,
                  )}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faInputNumeric}
                      className={styles.icon}
                    />
                    {polyglot.t('template.HSNCode')}
                  </div>
                  <div
                    className={cx(styles.containerCardElement, styles.containerCardElementRight)}
                  >
                    <div>
                      {`${vehicle.HSNCode || '-'} / ${vehicle.TSNCode || '-'}`}
                    </div>
                  </div>
                </>
                )}
                {vehicle.client.country === 'CH' && ((vehicle.baseNumberCh || vehicle.documentNumberCh) || !isMobileSize) && (
                <>
                  <div className={cx(
                    styles.containerCardElement,
                    styles.containerCardElementInline,
                  )}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faInputNumeric}
                      className={styles.icon}
                    />
                    {polyglot.t('template.baseNumberCh')}
                    {'\n'}
                    {polyglot.t('template.documentNumberCh')}
                  </div>
                  <div
                    className={cx(styles.containerCardElement, styles.containerCardElementRight)}
                  >
                    {vehicle.baseNumberCh}
                    {'\n'}
                    {vehicle.documentNumberCh}
                  </div>
                  <div className={styles.seperator} />
                </>
                )}
                {(vehicle.identificationNumber || !isMobileSize) && (
                  <div>
                    <div className={cx(
                      styles.containerCardElement,
                      styles.containerCardElementInline,
                    )}
                    >
                      <div className={styles.vehicleVIN}>
                        {'VIN'}
                      </div>
                      {polyglot.t('createClientWithDocument.form.vehicle.identificationNumber')}
                    </div>
                    <div className={cx(
                      styles.containerCardElement,
                      styles.containerCardElementRight,
                    )}
                    >
                      <div>
                        {vehicle.identificationNumber || ' '}
                      </div>
                    </div>
                  </div>
                )}

                {(vehicle.carClass || !isMobileSize) && (
                  <div className={cx(styles.containerCardElement, styles.containerCardElementBold)}>
                    <VehicleIcon style={{ fontSize: '17px' }} fixedWidth={5} fixedHeight={5} className={styles.icon} />
                    {getCarClassStringShort(polyglot, vehicle.carClass)}
                  </div>
                )}

                {(vehicle.typeOfTransmission || !isMobileSize) && (
                <>
                  <div className={cx(
                    styles.containerCardElement,
                    styles.containerCardElementInline,
                  )}>
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faGears}
                      className={styles.icon}
                    />
                    {polyglot.t('template.gearType')}
                  </div>
                  <div className={cx(
                    styles.containerCardElement,
                    styles.containerCardElementRight,
                  )}>
                    <div>
                      {typeOfTransmission}
                    </div>
                  </div>
                </>
                )}

                {(!isMobileSize && vehicle.additionalInfo) && (
                  <>
                    <div className={styles.seperator} />
                    <div className={cx(styles.containerCardElement)}>
                      <div>
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faInfoCircle}
                          className={styles.icon}
                        />
                      </div>
                      <div className={styles.containerCardElementBold}>
                        {vehicle.additionalInfo}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={styles.vehicleCardFormRowBlock}>
              <div style={{ float: 'right' }} className={cx(styles.containerCardDataDetails, styles.containerCardDualData)}>
                {((vehicle.ownWeight || vehicle.maxWeight) || !isMobileSize) && (
                  <>
                    {isMobileSize && (
                    <div className={styles.seperator} />
                    )}
                    <div className={styles.containerCardElement}>
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faWeightHanging}
                        className={styles.icon}
                      />
                      <div
                        className={cx(
                          styles.containerCardElementBold,
                          styles.containerCardElementInline,
                        )}
                      >
                        {`${this.formatNumberString(vehicle.ownWeight) || '--'} kg`}
                      </div>
                      <div
                        className={
                      cx(styles.containerCardElementRight, styles.containerCardElementInline)
                    }
                      >
                        <div className={styles.containerCardElementSmall}>
                          {'max.'}
                        </div>
                        {`${this.formatNumberString(vehicle.maxWeight) || '--'} kg`}
                      </div>
                    </div>
                  </>
                )}

                {((vehicle.enginePower || vehicle.engineDisplacement) || !isMobileSize) && (
                  <>
                    <div className={cx(styles.containerCardElement)}>
                      <div
                        className={cx(
                          styles.containerCardElementInline,
                          styles.containerCardElementBold,
                        )}
                      >
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faRoad}
                          className={styles.icon}
                        />
                      </div>
                      <div style={{ paddingTop: '0' }} className={cx(styles.containerCardElementBold)}>
                        <div>
                          {`${vehicle.enginePower || ''} kw / ${KWtoHP(vehicle.enginePower) || '--'} ${polyglot.t('vehicles.hp')}`}
                        </div>
                      </div>
                      <div
                        className={
                      cx(styles.containerCardElementInline, styles.containerCardElementRight)
                    }
                      >
                        <i className={cx('fak fa-displacement', styles.icon)} />
                        <div>
                          {`${vehicle.engineDisplacement || '--'} cm³`}
                        </div>
                      </div>
                    </div>

                    <div className={styles.seperator} />
                  </>
                )}

                {vehicle.dateOfNextInspection && (
                <div className={cx(styles.containerCardElement)}>
                  <CustomStatusLabel
                    title={polyglot.t('editVehicle.form.dateOfNextInspection.title')}
                    text={toUTCDay(vehicle.dateOfNextInspection) === 'Invalid date' ? ' ' : toUTCDay(vehicle.dateOfNextInspection)}
                    status={this.getNextInspectionStatus(vehicle)}
                    dataTest="vehicleInfoDateOfNextInspection"
                  />
                  {showNextInspectionButton && (
                  <div
                    className={
                      cx(styles.containerCardElementInline, styles.containerCardElementRight)
                    }
                  >
                    <ButtonModern
                      styleType="select"
                      size="small"
                      withIcon
                      onClick={() => this.nextInspectionForCountry(vehicle.client.country)
                      }
                      dataTest="vehicleInfoInspectedButton"
                    >
                      <FontAwesomeIcon className={styles.iconCheck} icon={faCheck} />
                    </ButtonModern>
                  </div>
                  )}
                </div>
                )}

                {((vehicle.dateOfLastRegistration
                || vehicle.dateOfFirstRegistration
                || vehicle.acceptanceDate)
                || !isMobileSize) && (
                  <>
                    <div className={cx(
                      styles.containerCardDetailsRowContainer,
                      styles.containerCardElement,
                    )}
                    >
                      <BiCalendar
                        fixedWidth={5}
                        className={cx(styles.icon, styles.containerCardDetailsRowIcon)}
                      />
                      <div className={styles.containerCardDetailsRowBlock}>
                        {(vehicle.dateOfLastRegistration || !isMobileSize) && (
                        <div className={styles.containerCardDetailsRow}>
                          <>
                            <div className={styles.containerCardDetailsRowElement}>
                              {polyglot.t('editVehicle.form.dateOfLastRegistration.title')}
                            </div>
                            <div className={cx(
                              styles.containerCardDetailsRowElement,
                              styles.containerCardElementBold,
                            )}>
                              {`${!vehicle.dateOfLastRegistration || toUTCDay(vehicle.dateOfLastRegistration) === 'Invalid date' ? '' : toUTCDay(vehicle.dateOfLastRegistration)}`}
                            </div>
                          </>
                        </div>
                        )}

                        {(vehicle.dateOfFirstRegistration || !isMobileSize) && (
                        <div className={styles.containerCardDetailsRow}>
                          <>
                            <div className={styles.containerCardDetailsRowElement}>
                              {polyglot.t('editVehicle.form.dateOfFirstRegistration.title')}
                            </div>
                            <div className={cx(
                              styles.containerCardDetailsRowElement,
                              styles.containerCardElementBold,
                            )}
                            >
                              {`${!vehicle.dateOfFirstRegistration || toUTCDay(vehicle.dateOfFirstRegistration) === 'Invalid date' ? '' : toUTCDay(vehicle.dateOfFirstRegistration)}`}
                            </div>
                          </>
                        </div>
                        )}

                        {(vehicle.acceptanceDate || !isMobileSize) && (
                        <div className={styles.containerCardDetailsRow}>
                          <>
                            <div className={styles.containerCardDetailsRowElement}>
                              {polyglot.t('editVehicle.form.acceptanceDate.title')}
                            </div>
                            <div className={cx(
                              styles.containerCardDetailsRowElement,
                              styles.containerCardElementBold,
                            )}
                            >
                              {`${!vehicle.acceptanceDate || toUTCDay(vehicle.acceptanceDate) === 'Invalid date' ? '' : toUTCDay(vehicle.acceptanceDate)}`}
                            </div>
                          </>
                        </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.seperator} />
                  </>
                )}

                {((vehicle.engineCategory
                || vehicle.engineCode)
                || !isMobileSize) && (
                  <>
                    <div className={cx(
                      styles.containerCardDetailsRowContainer,
                      styles.containerCardElement,
                    )}
                    >
                      <FontAwesomeIcon fixedWidth icon={faEngine} className={styles.icon} />
                      <div className={styles.containerCardDetailsRowBlock}>
                        {(vehicle.engineCategory || !isMobileSize) && (
                        <div className={styles.containerCardDetailsRow}>
                          <>
                            <div className={styles.containerCardDetailsRowElement}>
                              {polyglot.t('editVehicle.form.engineCategory.title')}
                            </div>
                            <div className={cx(
                              styles.containerCardDetailsRowElement,
                              styles.containerCardElementBold,
                            )}
                            >
                              {vehicle.engineCategory}
                            </div>
                          </>
                        </div>
                        )}

                        {(vehicle.engineCode || !isMobileSize) && (
                        <div className={styles.containerCardDetailsRow}>
                          <>
                            <div className={styles.containerCardDetailsRowElement}>
                              {polyglot.t('editVehicle.form.engineCode.title')}
                            </div>
                            <div className={cx(
                              styles.containerCardDetailsRowElement,
                              styles.containerCardElementBold,
                            )}
                            >
                              {vehicle.engineCode}
                            </div>
                          </>
                        </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.seperator} />
                  </>
                )}

                {(vehicle.colorCode || !isMobileSize) && (
                <div className={cx(styles.containerCardElement)}>
                  <FontAwesomeIcon fixedWidth icon={faPalette} className={styles.icon} />
                  {polyglot.t('editVehicle.form.colorCode.title')}
                  <div
                    className={
                      cx(styles.containerCardElementInline, styles.containerCardElementRight)
                    }
                  >
                    {`${vehicle.colorCode}`}
                  </div>
                </div>
                )}

                {(vehicle.customField || !isMobileSize) && (
                <div className={cx(styles.containerCardElement)}>
                  <FontAwesomeIcon fixedWidth icon={faStarOfLife} className={styles.icon} />
                  {garage.vehicleCustomFieldName}
                  <div className={cx(
                    styles.containerCardElementInline,
                    styles.containerCardElementRight,
                  )}>
                    {`${vehicle.customField}`}
                  </div>
                </div>
                )}

                {isMobileSize && vehicle.additionalInfo && (
                  <>
                    <div className={styles.seperator} />
                    <div className={cx(styles.containerCardElement)}>
                      <div>
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faInfoCircle}
                          className={styles.icon}
                        />
                      </div>
                      <div className={styles.containerCardElementBold}>
                        {vehicle.additionalInfo}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {showVehicleTires && (
          <AddonManager empty={true} features={['tires']} >
            <TireModalContainer
              vehicle={vehicle}
              render={(onOpenTire) => this.renderVehicleTiresList(onOpenTire)}
            />
          </AddonManager>
        )}
      </>
    );
  };

  renderVehicleTiresList = (onOpenTire) => {
    const { tires, totalAmountTires, isMobileSize } = this.props;
    return (
      <>
        <div className={styles.tireList}>
          <div className={styles.tireListHeaderContainer}>
            <div className={styles.tireListHeaderTitle}>
              <FontAwesomeIcon className={styles.tireListHeaderIcon} icon={faTire} />
              {polyglot.t('vehicles.tires')}
            </div>
            <div className={styles.totalAmountCircle}>
              {totalAmountTires}
            </div>
            {!isMobileSize && (
            <ButtonModern
              styleType={'select'}
              onClick={() => onOpenTire()}
              size={'small'}
              className={styles.tireListHeaderAdd}
            >
              <FontAwesomeIcon icon={faPlus} />
              {polyglot.t('vehicleTires.addTire')}
            </ButtonModern>
            )}
          </div>
          <TireCard
            onOpenTire={isMobileSize ? () => null : onOpenTire}
            showTireListCards
            tires={tires}
          />
        </div>
      </>
    );
  };

  renderRowCard = () => {
    const {
      vehicle,
      isSelected,
      dataTest,
      useHoverStyles,
      ignoreRemovedStyles,
      onClick,
      tires,
      isMobileSize,
      useHoverRemoveStyles,
      mileageReferenceDate,
    } = this.props;
    const { isRemoveIconHovered } = this.state;
    if (isMobileSize) return this.renderMobileRowCard();
    let mileage;
    if (vehicle.mileage[0]) {
      mileage = _.first(getSortedMileages(vehicle.mileage));
    }
    const tire = tires.slice(-1)[0];
    const mileageString = getMatchingMileageString(
      vehicle.mileage,
      mileageReferenceDate || new Date(),
    );
    const tireIcon = getTireIcon(tire);
    return (
      <InteractableDiv
        className={cx(
          styles.containerCard,
          styles.rowCard,
          { [styles.containerCardSelected]: isSelected },
          {
            [styles.containerCardHover]:
            useHoverStyles && !vehicle.removedOn && !isRemoveIconHovered,
          },
          { [styles.containerCardDeleted]: vehicle.removedOn && !ignoreRemovedStyles },
          { [styles.containerCardOnRemove]: isRemoveIconHovered || useHoverRemoveStyles },
        )}
        dataTest={dataTest}
        onClick={onClick}
      >
        <div className={cx(styles.rowCardBlock, styles.rowCardWrap)}>
          <div className={styles.rowCardWrap}>
            {this.renderCardNameWithLink()}
            <div>
              {vehicle.vehicleVersion}
            </div>
          </div>
          {vehicle.license && (
          <div>
            <License
              licenseNumber={vehicle.license}
              country={vehicle.client.country}
            />
          </div>
          )}
        </div>
        <div className={styles.rowCardBlock}>
          <div className={styles.rowCardWrapRight}>
            {tire && (
              <div className={styles.rowCardElement}>
                <div className={styles.rowCardTireIcon}>
                  {tireIcon()}
                </div>
                <div className={styles.rowCardRimContainer}>
                  <FontAwesomeIcon className={styles.storageIcon} icon={faDolly} />
                  {tire.storageLocation}
                </div>
              </div>
            )}
            {vehicle.dateOfNextInspection && (
              <div>
                <CustomStatusLabel
                  title={toUTCDay(vehicle.dateOfNextInspection) === 'Invalid date' ? ' ' : toUTCDay(vehicle.dateOfNextInspection)}
                  status={this.getNextInspectionStatus(vehicle)}
                  dataTest="vehicleInfoDateOfNextInspection"
                  className={styles.rowCardInspection}
                />
              </div>
            )}
            {mileage && (
              <div className={styles.rowCardMileage}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faRoad}
                  className={cx(
                    styles.icon,
                    styles.iconRow,
                  )}
                />
                <div className={cx(
                  {
                    [styles.rowCardMileageOld]: mileageString
                    && mileageReferenceDate
                    && Math.abs(moment(mileageReferenceDate).diff(moment(), 'days')) > 30,
                  },
                )}
                >
                  {!mileageString ? '? km' : mileageString}
                </div>
              </div>
            )}
          </div>
          {this.renderIconAndActions(false, false, true)}
        </div>
      </InteractableDiv>
    );
  };

  renderMobileRowCard = () => {
    const {
      vehicle,
      isSelected,
      dataTest,
      useHoverStyles,
      ignoreRemovedStyles,
      onClick,
      tires,
      useHoverRemoveStyles,
      mileageReferenceDate,
    } = this.props;
    const { isRemoveIconHovered } = this.state;
    let mileage;
    if (vehicle.mileage[0]) {
      mileage = _.first(getSortedMileages(vehicle.mileage));
    }
    const tire = tires.slice(-1)[0];
    const mileageString
    = getMatchingMileageString(vehicle.mileage, mileageReferenceDate || new Date());
    const tireIcon = getTireIcon(tire);
    return (
      <InteractableDiv
        className={cx(
          styles.containerCard,
          { [styles.containerCardSelected]: isSelected },
          {
            [styles.containerCardHover]:
            useHoverStyles && !vehicle.removedOn && !isRemoveIconHovered,
          },
          { [styles.containerCardDeleted]: vehicle.removedOn && !ignoreRemovedStyles },
          { [styles.containerCardOnRemove]: isRemoveIconHovered || useHoverRemoveStyles },
        )}
        dataTest={dataTest}
        onClick={onClick}
      >
        <div className={cx(styles.containerCardData, styles.rowCardMobile)}>
          <div className={styles.rowCardTitleContainer}>
            {this.renderCardNameWithLink()}
            <div>
              {vehicle.vehicleVersion}
            </div>
          </div>
          <div className={cx(styles.rowCardBlock, styles.rowCardBlockMobile)}>
            {vehicle.license && (
              <div>
                <License
                  licenseNumber={vehicle.license}
                  country={vehicle.client.country}
                />
              </div>
            )}
            {tire && (
            <div className={styles.rowCardElement}>
              <div className={styles.rowCardTireIcon}>
                {tireIcon()}
              </div>
              <div className={styles.rowCardRimContainer}>
                <FontAwesomeIcon className={styles.storageIcon} icon={faDolly} />
                {tire.storageLocation}
              </div>
            </div>
            )}
          </div>

          <div className={cx(styles.rowCardBlock, styles.rowCardBlockMobile)}>
            {vehicle.dateOfNextInspection && (
            <div>
              <CustomStatusLabel
                title={toUTCDay(vehicle.dateOfNextInspection) === 'Invalid date' ? ' ' : toUTCDay(vehicle.dateOfNextInspection)}
                status={this.getNextInspectionStatus(vehicle)}
                dataTest="vehicleInfoDateOfNextInspection"
                className={styles.rowCardInspection}
              />
            </div>
            )}
            {mileage && (
              <div className={styles.rowCardMileage}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faRoad}
                  className={cx(
                    styles.icon,
                    styles.iconRow,
                  )}
                />
                <div className={cx(
                  {
                    [styles.rowCardMileageOld]: mileageString
                    && mileageReferenceDate
                    && Math.abs(moment(mileageReferenceDate).diff(moment(), 'days')) > 30,
                  },
                )}
                >
                  { !mileageString ? '? km' : mileageString}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.renderIconAndActions()}
      </InteractableDiv>
    );
  };

  render() {
    const {
      showVehicleDetails,
      showVehicleListCards,
      showVehicleRowCard,
      vehicle,
      dataTest,
    } = this.props;
    const { VehicleIcon } = this.state;
    if (showVehicleDetails) { return this.renderVehicleDetailsCard(); }
    if (showVehicleListCards) { return this.renderVehicleListCard(); }
    if (showVehicleRowCard) { return this.renderRowCard(); }
    return (
      <div dataTest={dataTest} style={{ paddingRight: 0 }} className={styles.containerCardListRow}>
        <div style={{ paddingTop: '0px' }} className={styles.containerCardElementList}>
          <div className={cx(styles.truncateFormat, styles.truncateFormatCardName)}>
            {vehicle.manufacturer || '-'}
            {'\t'}
            {vehicle.model || '-'}
          </div>
        </div>
        <div className={cx(styles.containerCardElementListRow)}>
          <div className={styles.licenseContainer}>
            <License
              licenseNumber={vehicle.license}
              country={vehicle.client?.country}
            />
          </div>

          {vehicle.dateOfFirstRegistration && (
          <div className={cx(
            styles.containerCardElementListRowItem,
            styles.containerCardElementListRowItemRight,
          )}>
            <BiCalendar style={{ fontSize: '18px' }} fixedWidth={5} className={styles.icon} />
            {`${toUTCDay(vehicle.dateOfFirstRegistration) === 'Invalid date' ? '' : toUTCDay(vehicle.dateOfFirstRegistration)}`}
          </div>
          )}
        </div>
        <div style={{ marginTop: '-60px' }} className={styles.containerImageVehicle}>
          <VehicleIcon className={styles.containerImageVehicleIcon}> </VehicleIcon>
        </div>
      </div>
    );
  }
}
