/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormValidator from 'helpers/formValidation/formValidator';
import Tooltip from 'components/common/tooltip';

/**
 * Class that extends PureComponent and adds Validator functionality.
 * @state Manages hover and focus
 * @props Manage onMouseLeave, onMouseEnter, onFocus and onBlur
 */
export default class ValidatableComponent extends PureComponent {
  static propTypes = {
    formValidator: FormValidator.getShape(),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    isValid: PropTypes.bool,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    formValidator: null,
    onMouseEnter: null,
    onMouseLeave: null,
    onFocus: null,
    onBlur: null,
    onChange: null,
    errorMessage: '',
    isValid: false,
    isRequired: false,
  };

  constructor(props) {
    super(props);
    const { formValidator } = props;

    if (formValidator) {
      formValidator.registerOnUpdateListener(() => {
        this.forceUpdate();
      });
    }

    this.state = {
      isFocused: false,
      isHovered: false,
    };

    // Bind context to functions
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.onChange = this.onChange.bind(this);

    // Timeout in case child set its own state
    setTimeout(() => this.setState({ isFocused: false, isHovered: false }), 1);
  }

  componentDidUpdate(prevProps) {
    const { formValidator } = this.props;
    if (prevProps.formValidator && formValidator && prevProps.formValidator !== formValidator) {
      formValidator.registerOnUpdateListener(() => {
        this.forceUpdate();
      });
    }
  }

  onMouseEnter(event) {
    const { onMouseEnter } = this.props;
    this.setState({ isHovered: true });
    if (onMouseEnter) onMouseEnter(event);
  }

  onMouseLeave(event) {
    const { onMouseLeave } = this.props;
    this.setState({ isHovered: false });
    if (onMouseLeave) onMouseLeave(event);
  }

  onFocus(event) {
    setTimeout(() => {
      const { onFocus } = this.props;
      this.setState({ isFocused: true });
      if (onFocus) onFocus(event);
    }, 2);
  }

  onBlur(event) {
    const { formValidator, onBlur } = this.props;
    if (formValidator) {
      formValidator.touched = true;
    }
    this.setState({ isFocused: false });
    if (onBlur) onBlur(event);
  }

  hasError() {
    const { errorMessage, isValid, formValidator } = this.props;
    return formValidator
      ? (formValidator.touched && formValidator.hasError())
      : (!isValid || !!errorMessage);
  }

  isRequired() {
    const { isRequired, formValidator } = this.props;
    return isRequired
    || (formValidator && formValidator.validators.find((validator) => validator.validatorId === 'required'));
  }

  renderTooltip() {
    const { errorMessage, formValidator } = this.props;
    const { isHovered, isFocused } = this.state;

    let message = errorMessage || '';
    if (formValidator) {
      if (formValidator.getCurrentErrorToolTip()) {
        message = formValidator.getCurrentErrorToolTip();
      } else if (formValidator.getCurrentErrorMessage()) {
        message = formValidator.getCurrentErrorMessage();
      }
    }

    return (
      <Tooltip variant="error" message={message} isVisable={this.hasError() && (isFocused || isHovered)} />
    );
  }

  onChange(newVal) {
    const { onChange, formValidator } = this.props;
    if (formValidator) {
      let extractedVal = newVal;
      if (newVal && newVal.target) extractedVal = newVal.target.value;
      formValidator.set(extractedVal);
    }
    if (onChange) {
      onChange(newVal);
    }
  }
}
