import { last } from 'lodash/array';

import { updateAppByAction } from 'resources/utils';
import { UPDATE_GARAGE } from 'resources/garage/garage.actions';
import localStorage from 'services/localStorage';
import {
  FETCH_USERS,
  UPDATE_USER,
  REMOVE_USERS,
  RESET_USERS_STATE,
  CHANGE_USERS_FILTER,
  TOGGLE_CUSTOMER_MODE,
  SUBMIT_RATING,
} from './user.actions';

const getLocalCurrentGarageId = (user) => {
  const { garages, currentGarageId } = user;

  const localId = localStorage.getItem('currentGarageId');
  if (localId) {
    const currentGarage = garages.find(({ garageId }) => localId === garageId);
    if (currentGarage) {
      if (window.config.garage._id !== currentGarage.garageId) {
        if (window.location.href.includes('?')) {
          window.location = `${window.location.href}&loadGarage=${currentGarage.garageId}`;
        } else {
          window.location = `${window.location.href}?loadGarage=${currentGarage.garageId}`;
        }
      }
      return currentGarage.garageId;
    }
  }
  const currentGarage = garages.find(({ garageId }) => currentGarageId === garageId);
  const currGarageId = currentGarage ? currentGarage.garageId : null;
  localStorage.setItem('currentGarageId', currGarageId);
  return currGarageId;
};

const setCustomerMode = () => {
  const { garages, currentGarageId } = window.config.user;
  const currentGarage = garages.find(({ garageId }) => currentGarageId === garageId);

  if (!currentGarage) return null;

  if (currentGarage.roles.includes('admin') || currentGarage.roles.includes('office')) {
    return !!localStorage.getItem('isCustomerMode');
  }

  if (currentGarage.roles.includes('worker')) {
    return true;
  }

  if (currentGarage.roles.includes('accountant')) {
    return false;
  }

  return !!localStorage.getItem('isCustomerMode');
};

const initialState = {
  currentUser: {
    ...window.config.user,
    currentGarageId: getLocalCurrentGarageId(window.config.user),
    createdOn: new Date(window.config.user.createdOn),
    updatedOn: window.config.user.updatedOn ? new Date(window.config.user.updatedOn) : undefined,
  },
  list: [],
  isCustomerMode: setCustomerMode(),
};
const actionTypesToUpdateApp = [UPDATE_USER, REMOVE_USERS];

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  updateAppByAction(actionTypesToUpdateApp, action.type);

  switch (action.type) {
    case FETCH_USERS: {
      // do not update if case of multiple identical responses
      const lastFromState = last(state.list);
      const lastFromResponse = last(action.payload.results);
      if (lastFromState && lastFromResponse && lastFromState._id === lastFromResponse._id) {
        return state;
      }

      return {
        ...state,
        list: [...state.list, ...action.payload.results],
        totalAmount: action.payload.count,
      };
    }

    case RESET_USERS_STATE:
      return {
        ...initialState,
        list: [],
        currentUser: state.currentUser,
        isCustomerMode: setCustomerMode(),
      };

    case CHANGE_USERS_FILTER:
      return {
        ...state,
        list: action.payload.results || [],
        totalAmount: action.payload.count,
      };

    case UPDATE_USER: {
      if (action.payload._id === state.currentUser._id) {
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            ...action.payload,
            currentGarageId: getLocalCurrentGarageId(action.payload),
          },
        };
      }

      return {
        ...state,
        list: state.list.map((u) => {
          if (u._id === action.payload._id) {
            return { ...u, ...action.payload };
          }
          return u;
        }),
      };
    }

    case UPDATE_GARAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          garages: state.currentUser.garages.map((garage) => {
            return garage.garageId === action.payload._id
              ? { ...garage, garageName: action.payload.title }
              : garage;
          }),
        },
      };

    case REMOVE_USERS:
      return {
        ...state,
        list: state.list.filter((u) => !action.userIds.includes(u._id)),
        totalAmount: state.totalAmount - action.userIds.length,
      };

    case TOGGLE_CUSTOMER_MODE: {
      const isCustomerMode = !state.isCustomerMode;
      localStorage.setItem('isCustomerMode', isCustomerMode);
      return {
        ...state,
        isCustomerMode,
      };
    }

    case SUBMIT_RATING:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          rating: action.payload,
        },
      };

    default:
      return state;
  }
};
