const carClasses = [
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'L1e',
  'L2e',
  'L3e',
  'L4e',
  'L5e',
  'L6e',
  'L7e',
  'M1',
  'M2',
  'M3',
  'N1',
  'N2',
  'N3',
  'O1',
  'O2',
  'O3',
  'O4',
  'R1',
  'R2',
  'R3',
  'R4',
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
];

function getCarClassStringFull(polyglot, carClass) {
  return `${carClass} | ${polyglot.t(`carClass.${carClass}`)}`;
}

function getCarClassStringShort(polyglot, carClass) {
  return carClass ? getCarClassStringFull(polyglot, carClass).split('(')[0] : '';
}
function getCarClassesDropdown(polyglot, carClassesArr = []) {
  return carClassesArr.map((carClass) => ({
    key: carClass,
    label: getCarClassStringFull(polyglot, carClass),
  }));
}

module.exports = {
  carClasses,
  getCarClassStringFull,
  getCarClassStringShort,
  getCarClassesDropdown,
};
