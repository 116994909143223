import cx from 'classnames';
import React from 'react';

export class MailErrorNotification {
  constructor(notification, { history }) {
    this.notification = notification;
    this.history = history;
  }

  Icon({ className }) {
    return <i className={cx('fa-kit fa-solid-envelope-circle-xmark', className)}></i>;
  }

  getInvoiceStatus = (invoiceType) => {
    return ['open', 'paid', 'paidCredit'].includes(invoiceType) ? 'invoice' : invoiceType;
  };

  getNotificationText(polyglot) {
    return polyglot.t('notifications.mailErrorText', {
      documentNumber: this.notification.metaData.documentNumber,
      invoiceStatus: polyglot.t(`invoices.statuses.${this.getInvoiceStatus(this.notification.metaData.status)}`),
      recipient: this.notification.metaData.recipient,
    });
  }

  onClickAction() {
    this.history.push(`/${this.notification.metaData.status === 'offer' ? `offers/${this.notification.metaData.offerId}/emailState/${this.notification.metaData.pdfType}` : `invoices/view/${this.notification.metaData.invoiceId}/emailState/${this.notification.metaData.pdfType}`}`);
  }
}
