import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePickerComponent, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { it, de, enGB } from 'date-fns/locale';
import MaskedInput from 'react-text-mask';
import moment from 'moment';

import polyglot from 'services/localization';

import { DATE_FORMAT_MOMENT, DATE_FORMAT } from 'constants.js';

import styles from './datePicker.styles.pcss';

registerLocale('it', it);
registerLocale('de', de);
registerLocale('en-gb', enGB);
setDefaultLocale('de');

const DATEPICKER_LOCALE = {
  en_US: 'en-gb', // eslint-disable-line camelcase
  de_DE: 'de', // eslint-disable-line camelcase
  it_IT: 'it', // eslint-disable-line camelcase
  fr_FR: 'fr', // eslint-disable-line camelcase
};

const MASKS_BY_VALUE = {
  0: /[1-9]/,
  1: /[0-2]/,
  3: /[0-1]/,
};

const getMaskByValue = (value, keys, fallback = /\d/) => (keys.includes(value) ? MASKS_BY_VALUE[value] : fallback);

const maskFunction = (rawValue) => [
  /[0-3]/,
  getMaskByValue(rawValue[0], ['0', '3']),
  '.',
  /[0-1]/,
  getMaskByValue(rawValue[3], ['0', '1']),
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const DateInput = ({
  isValid,
  onClick,
  index,
  isReadOnly,
  value,
  onChange,
  dataTest,
  ...props
}) => (
  <MaskedInput
    className={cx(styles.datePickerField, {
      [styles.datePickerFieldError]: !isValid,
    })}
    value={value}
    mask={maskFunction}
    placeholder={polyglot.t('datePicker.placeholder')}
    onBlur={onChange}
    onChange={onChange}
    onClick={onClick}
    tabIndex={index}
    readOnly={isReadOnly}
    dataTest={dataTest}
  />
);

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isValid: PropTypes.bool,
  index: PropTypes.number,
  isReadOnly: PropTypes.bool,
  dataTest: PropTypes.string,
};

DateInput.defaultProps = {
  value: '',
  onChange: () => {},
  onClick: () => {},
  isValid: true,
  index: 0,
  isReadOnly: false,
  dataTest: '',
};

const DatePicker = ({
  className,
  name,
  label,
  isRequired,
  value,
  onChange,
  errorText,
  tabIndex,
  readOnly,
  isRow,
  startDate,
  endDate,
  ...props
}) => {
  let parsedDateObject = moment(value, DATE_FORMAT_MOMENT).toDate();
  if (Number.isNaN(parsedDateObject.getTime())) parsedDateObject = null;

  return (
    <div className={cx(className, styles.datePicker)}>
      <DatePickerComponent
        name={name}
        selected={value === '' ? null : parsedDateObject}
        locale={DATEPICKER_LOCALE[window.config.user.locale]}
        dateFormat={DATE_FORMAT}
        onChange={(date) => onChange(date === null ? '' : moment(date).format(DATE_FORMAT_MOMENT))}
        customInput={
          <DateInput
            isValid={!errorText}
            index={tabIndex}
            isReadOnly={readOnly}
            {...props}
          />
        }
        value={value}
        startDate={startDate}
        endDate={endDate}
        {...props}
      />
      <label
        htmlFor={name}
        className={cx(styles.datePickerLabel, {
          [styles.datePickerLabelColumn]: !isRow,
        })}
      >
        {label && (
          <div className={styles.datePickerLabelText}>
            <div>
              {label}
            </div>
            {isRequired && <div className={styles.datePickerRequiredMark}>
              *
            </div>}
          </div>
        )}
      </label>
      {errorText && <div className={styles.datePickerError}>
        {errorText}
      </div>}
    </div>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  tabIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  isRow: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  dataTest: PropTypes.string,
};

DatePicker.defaultProps = {
  className: '',
  label: '',
  isRequired: false,
  errorText: '',
  tabIndex: 0,
  readOnly: false,
  isRow: false,
  startDate: null,
  endDate: null,
  dataTest: '',
};

export default DatePicker;
