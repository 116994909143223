import { downloadFile } from 'helpers/file';
import { getServerCorrectDate } from 'helpers/date';
import { addPageSize } from 'resources/utils';
import * as fromUser from 'resources/user/user.selectors';
import * as api from './clients.api';

const getValidClientData = (data) => {
  return {
    ...data,
    dateOfBirthday: getServerCorrectDate(data.dateOfBirthday),
  };
};

export const FETCH_CLIENTS = 'fetchClients';
export const FETCH_CLIENT = 'fetchClient';
export const RESET_CLIENTS_STATE = 'resetClientsState';
export const CREATE_CLIENT = 'createClient';
export const CREATE_CLIENT_WITH_DOCUMENT = 'createClientWithDocument';
export const REMOVE_CLIENT = 'removeClient';
export const UPDATE_CLIENT = 'updateClient';
export const CHANGE_CLIENTS_FILTER = 'changeClientsFilter';
export const GET_CLIENTS_CSV = 'changeClientsFilter';
export const RESET_CURRENT_CLIENT = 'resetCurrentClient';
export const RESTORE_CLIENT = 'restoreClient';

export const fetchClients = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchClients(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_CLIENTS, data, payload }));
};

export const fetchClientsBirthdayList = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchClientsBirthdayList(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_CLIENTS, data, payload }));
};

export const fetchSameClients = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchSameClients(currentGarageId, data)
    .then((payload) => dispatch({ type: FETCH_CLIENTS, data, payload }));
};

export const fetchClient = (id, data = {}) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  return api.fetchClient(currentGarageId, id, data)
    .then((payload) => dispatch({ type: FETCH_CLIENT, payload }));
};

export const changeClientsFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchClients(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_CLIENTS_FILTER, data, payload }));
};

export const changeClientsBirthdayListFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchClientsBirthdayList(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_CLIENTS_FILTER, data, payload }));
};

export const changeSameClientsFilter = (options = {}) => (dispatch, getState) => {
  const data = addPageSize(options);
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.fetchSameClients(currentGarageId, data)
    .then((payload) => dispatch({ type: CHANGE_CLIENTS_FILTER, data, payload }));
};

export const resetClientsState = () => ({ type: RESET_CLIENTS_STATE });

export const resetCurrentClient = () => ({ type: RESET_CURRENT_CLIENT });

export const createClient = (data) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  const validData = getValidClientData(data);

  return api.createClient(currentGarageId, validData)
    .then((payload) => dispatch({ type: CREATE_CLIENT, data: validData, payload }));
};

export const createClientWithDocument = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.createClientWithDocument(currentGarageId, data)
    .then((payload) => {
      if (window.config.isDev || window.config.isStaging) {
        downloadFile(payload.text, 'OCR.txt');
      }
      dispatch({ type: CREATE_CLIENT_WITH_DOCUMENT, payload });
      return payload;
    });
};

export const createClientWithDocumentConfirmation = ({ vehicle, client, image }) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentGarageId = fromUser.getCurrentGarageId(state);
    const validData = {
      vehicle: {
        ...vehicle,
        dateOfFirstRegistration: vehicle.dateOfFirstRegistration
          ? getServerCorrectDate(vehicle.dateOfFirstRegistration) : null,
        acceptanceDate: vehicle.acceptanceDate
          ? getServerCorrectDate(vehicle.acceptanceDate) : null,
        dateOfNextInspection: vehicle.dateOfNextInspection
          ? getServerCorrectDate(vehicle.dateOfNextInspection) : null,
        dateOfLastRegistration: vehicle.dateOfLastRegistration
          ? getServerCorrectDate(vehicle.dateOfLastRegistration) : null,
        enginePower: vehicle.enginePower || null,
        ownWeight: vehicle.ownWeight || null,
        maxWeight: vehicle.maxWeight || null,
        engineDisplacement: vehicle.engineDisplacement || null,
      },
      client: getValidClientData(client),
      image,
    };

    return api.createClientWithDocumentConfirmation(currentGarageId, validData);
  };
};

export const removeClients = (clientIds) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.removeClients(currentGarageId, clientIds)
    .then((payload) => dispatch({ type: REMOVE_CLIENT, clientIds, payload }));
};

export const updateClient = (data) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());
  const validData = getValidClientData(data);

  return api.updateClient(currentGarageId, validData)
    .then((payload) => dispatch({ type: UPDATE_CLIENT, data: validData, payload }));
};

export const mergeClients = (clientId, oldClientId) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());

  return api.mergeClients(currentGarageId, clientId, oldClientId)
    .then((payload) => {
      dispatch({ type: REMOVE_CLIENT, clientIds: [oldClientId], payload });
    });
};

export const updateClientMessagePdf = (clientId, idx, titel, message) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());

  return api.updateClientMessagePdf(currentGarageId, clientId, idx, titel, message);
};

export const removeClientMessagePdf = (clientId, idx) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());

  return api.removeClientMessagePdf(currentGarageId, clientId, idx);
};

export const createClientMessagePdf = (clientId, titel, message) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());

  return api.createClientMessagePdf(currentGarageId, clientId, titel, message);
};

export const getClientMessagePdf = (clientId, idx) => (dispatch, getState) => {
  const currentGarageId = fromUser.getCurrentGarageId(getState());

  return api.getClientMessagePdf(currentGarageId, clientId, idx);
};

export const getClientsCSV = () => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.getClientsCSV(currentGarageId)
    .then((payload) => downloadFile(payload));
};

export const getClientsBMDCSV = () => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.getClientsBMDCSV(currentGarageId)
    .then((payload) => downloadFile(payload));
};

export const getClientsDatevCSV = () => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.getClientsDatevCSV(currentGarageId)
    .then((payload) => downloadFile(payload, 'EXTF_report.csv'));
};

export const getClientsBirthdayListCSV = (selectedMonth) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.getClientsBirthdayListCSV(currentGarageId, selectedMonth)
    .then((payload) => downloadFile(payload));
};

export const uploadClientImage = (clientId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.uploadClientImage(currentGarageId, clientId, data)
    .then((payload) => dispatch({
      type: UPDATE_CLIENT,
      clientId,
      data,
      payload,
    }));
};

export const removeClientImages = (clientId, filesToRemove) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.removeClientImages(currentGarageId, clientId, filesToRemove)
    .then((payload) => dispatch({
      type: UPDATE_CLIENT,
      clientId,
      filesToRemove,
      payload,
    }));
};

export const updateClientImage = (clientId, imageId, data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);

  return api.updateClientImage(currentGarageId, clientId, imageId, data)
    .then((payload) => dispatch({
      type: UPDATE_CLIENT,
      clientId,
      imageId,
      data,
      payload,
    }));
};

export const restoreClient = (data) => (dispatch, getState) => {
  const state = getState();
  const currentGarageId = fromUser.getCurrentGarageId(state);
  return api.restoreClient(currentGarageId, data)
    .then((payload) => dispatch({ type: RESTORE_CLIENT, data, payload }));
};
