import React, { PureComponent } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faPlusSquare } from 'fontawesome/pro-solid-svg-icons';

import PropTypes from 'prop-types';
import { MdModeEdit as EditIcon } from 'react-icons/md';
import pageStyles from 'styles/page.pcss';
import styles from './linkButton.styles.pcss';

class LinkButton extends PureComponent {
  static BUTTON_TYPES = {
    PLAIN: 'plain',
    EDIT: 'edit',
    ADD_SQUARE: 'addSquare',
  };

  static ICON_POSITIONS = {
    LEFT: 'left',
    RIGHT: 'right',
  };

  static propTypes = {
    type: PropTypes.oneOf(Object.values(LinkButton.BUTTON_TYPES)),
    iconPosition: PropTypes.oneOf(Object.values(LinkButton.ICON_POSITIONS)),
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    inline: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dataTest: PropTypes.string,
  };

  static defaultProps = {
    type: 'plain',
    onClick: null,
    tabIndex: 0,
    inline: false,
    iconPosition: 'left',
    className: '',
    disabled: false,
    dataTest: '',
  };

  state = { };

  getIcon = () => {
    const { type, disabled } = this.props;
    if (disabled) return null;
    const types = LinkButton.BUTTON_TYPES;
    switch (type) {
      case types.EDIT:
        return <EditIcon className={styles.linkButtonEditIcon} />;
      case types.ADD_SQUARE:
        return <FontAwesomeIcon icon={faPlusSquare} className={styles.linkButtonFaIcon} />;
      default:
        return null;
    }
  };

  render() {
    const {
      children,
      type,
      onClick,
      tabIndex,
      inline,
      iconPosition,
      className,
      disabled,
      dataTest,
    } = this.props;

    const style = {};
    if (inline) style.display = 'inline-block';

    return (
      <div
          className={cx(
            styles.linkButtonContainer,
            className,
            disabled ? styles.linkButtonDisabled : null,
          )}
          style={style}
        >
        <div
            tabIndex={tabIndex}
            role="button"
            onClick={onClick}
            dataTest={dataTest}
            className={cx(pageStyles.pageLink, styles.linkButtonLink)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onClick();
              }
            }}
          >
          { type !== LinkButton.BUTTON_TYPES.PLAIN
              && iconPosition === LinkButton.ICON_POSITIONS.LEFT
              && this.getIcon()
            }
          {children}
          { type !== LinkButton.BUTTON_TYPES.PLAIN
              && iconPosition === LinkButton.ICON_POSITIONS.RIGHT
              && this.getIcon()
            }
        </div>
      </div>
    );
  }
}

export default LinkButton;
