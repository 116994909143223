export const getErrors = (err) => {
  let errors = null;
  if (err instanceof Error) {
    try {
      errors = JSON.parse(err.message).errors;
    } catch (e) {
      errors = null;
    }
  }

  return errors;
};

export const parseAllErrors = (err) => {
  const errors = getErrors(err);

  if (errors && errors.length) {
    const errorObject = errors.reduce((acc, error) => ({ ...acc, ...error }), {});
    return errorObject;
  }

  return null;
};

export function handleError(err) {
  return parseError(err) || err.message;
}

export default function parseError(err) {
  const errors = getErrors(err);

  if (errors && errors.length) {
    const errorText = Object.values(errors[0]);
    return errorText[0];
  }

  return null;
}
