import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonLoading from 'components/common/loading/components/buttonLoading/buttonLoading';
import buttonStyles from 'styles/button.pcss';

const CLASS_BY_STYLE = {
  add: buttonStyles.buttonAdd,
  addInline: buttonStyles.buttonAddInline,
  remove: buttonStyles.buttonRemove,
  export: buttonStyles.buttonExport,
  info: buttonStyles.buttonInfo,
};

const Button = ({
  className, styleType, onClick, isLoading, disabled, children, dataTest, ...props
}) => (
  <button
    type="button"
    className={cx(buttonStyles.button, CLASS_BY_STYLE[styleType], className)}
    disabled={isLoading || disabled}
    onClick={onClick}
    dataTest={dataTest}
    {...props}
  >
    {isLoading ? <ButtonLoading /> : children}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  styleType: PropTypes.oneOf(['add', 'remove', 'export', 'info', '']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  styleType: '',
  disabled: false,
  isLoading: false,
  dataTest: '',
};

export default Button;
